import Toast from "components/Toast";
import { getToken } from "helpers";
import { useAxios } from "hooks/useAxios";
import { useEffect, useReducer, useState } from "react";
import { baseTheme } from "styles/theme";
import Spinner from "components/Spinners/ButtonSpinner";
import { Container, FieldContainer, SpinnerWrapper } from "./style";
import { TitleSection } from "styles/components";
import { styled } from "styled-components";
import EditableField from "./EditableItem";

import DeleteCustomField from "./DeleteCustomField";
import InformModal from "components/Modal/ConfirmModal";

const Pt20 = styled.div`
  padding-top: 30px;
`;
interface CustomOptionsFieldsProps {
  data: { id: string; name: string; "custom-field-values": [] };
  getCustomFields: () => void;
  setData?: any;
  closeModal: (isOpen: boolean) => void;
}

function CustomToggleField({
  data,
  getCustomFields,
  setData,
  closeModal,
}: CustomOptionsFieldsProps) {
  const token = getToken();
  const [showModal, toggleModal] = useReducer((showModal) => !showModal, false);
  const { request, error } = useAxios();
  const [options, setOptions] = useState<{ id: string; value: string }[]>([]);
  const [loader, setLoader] = useState(false);
  useEffect(() => {
    if (data !== null) {
      if (data["custom-field-values"]) {
        setOptions(data["custom-field-values"]);
      }
    }
  }, [data]);

  const handleChangeName = async (newValue: string, id?: string) => {
    setLoader(true);
    const customFieldValues = options.map((option) => option.value);

    const dataRequest = !options
      ? {
          name: newValue,
          type: "toggle",
        }
      : {
          name: newValue,
          type: "toggle",
          "custom-field-values": customFieldValues,
        };

    const res = await request(
      `profile/custom-field/${data?.id}`,
      "POST",
      dataRequest,
      {
        Authorization: `Bearer ${token}`,
      }
    );

    if (res) {
      setData(res.data);
      getCustomFields();
    }
    setLoader(false);
  };
  const handleChangeOption = async (newValue: string, id?: string) => {
    setLoader(true);
    const updatedOptions = options.map((option) =>
      option.id === id ? { ...option, value: newValue } : option
    );

    const customFieldValues = updatedOptions.map((option) => option.value);

    const res = await request(
      `profile/custom-field/${data?.id}`,
      "POST",
      {
        name: data?.name,
        type: "toggle",
        "custom-field-values": customFieldValues,
      },
      {
        Authorization: `Bearer ${token}`,
      }
    );

    if (res) {
      setData(res.data);
      getCustomFields();
    }
    setLoader(false);
  };
  const onDelete = async () => {
    toggleModal();
    setLoader(true);
    const res = await request(
      `profile/custom-field/${data?.id}/delete`,
      "GET",
      null,
      {
        Authorization: `Bearer ${token}`,
      }
    );
    if (res.message === "Entity successful deleted.") {
      closeModal(false);
      await getCustomFields();
    }
    setLoader(false);
  };
  return (
    <Container>
      <>
        <TitleSection>{`Edit ${data.name} field`}</TitleSection>
        {loader ? (
          <SpinnerWrapper>
            <Spinner color={baseTheme.colors.main} />
          </SpinnerWrapper>
        ) : (
          <>
            <EditableField
              showButton={false}
              id={data?.id}
              value={data.name}
              onSave={handleChangeName}
              onDelete={() => {}}
            />
            <>
              <Pt20 />
              <TitleSection>{`Edit ${data?.name} options field`}</TitleSection>
              {options?.[0] && (
                <EditableField
                  showButton={false}
                  id={options[0].id}
                  value={options[0].value}
                  onSave={handleChangeOption}
                  onDelete={() => {}}
                />
              )}
              {options?.[1] && (
                <EditableField
                  showButton={false}
                  id={options[1].id}
                  value={options[1].value}
                  onSave={handleChangeOption}
                  onDelete={() => {}}
                />
              )}

              {data !== null && (
                <FieldContainer>
                  <DeleteCustomField name={data?.name} onDelete={toggleModal} />
                </FieldContainer>
              )}
            </>
          </>
        )}
      </>
      <InformModal
        isOpen={showModal}
        cancelButton={toggleModal}
        confirmButton={onDelete}
        title={"Please confirm deleting item from list"}
      />
      {error && <Toast error={error} />}
    </Container>
  );
}

export default CustomToggleField;
