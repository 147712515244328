import Input from "components/Inputs/Input";
import {  Section, StyledLabel } from "./styles";
import { ChangeEvent } from "react";
import { LandingContent, useLandingContentStore } from "store/useLandingContentStore";


function Price() {
    const { landingContent, updateField } = useLandingContentStore();
  
    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
      updateField(e.target.name as keyof LandingContent, e.target.value);
    };
  return (
    <Section>
      <StyledLabel>Price text</StyledLabel>
      <Input
        placeholder="Enter price text"
        name="price"
        value={landingContent.price}
        onChange={handleInputChange}
      />
    </Section>
  );
}

export default Price