import SupervisionLevel from "pages/UserSettingsPage/components/SupervisionLevel";
import { ProfileSectionTitle } from "./AccountSettings";
import styled from "styled-components";
import { AccountSettingsHeader } from "./AccountDetails";
import Button from "components/Buttons/Button";
import { UrgencyButtons } from "./SetUrgency";
import CustomFieldsList from "./CustomFieldsList";
import { useReducer, useState } from "react";
import { useAxios } from "hooks/useAxios";
import { getToken } from "helpers";
import InformModal from "components/Modal/ConfirmModal";
import CheckOutlined from "@ant-design/icons/CheckOutlined";
import { baseTheme } from "styles/theme";

const CustomFieldsWrapper = styled.div`
  width: 400px;
`;

interface ICustomFieldsSettings {
  settingsProfile: () => {};
  dataSettingsProfile: any;
}

function CustomFieldsSettings({
  settingsProfile,
  dataSettingsProfile,
}: ICustomFieldsSettings) {
  const [supervisionLevel, setSupervisionLevel] = useState<string>("");
  const { request, loading } = useAxios();
  const token = getToken();
  const [show, toggle] = useReducer((show) => !show, false);

  async function updateSupervisionLevel() {
    const res = await request(
      "profile/presets",
      "POST",
      {
        presets: {
          supervision_level: supervisionLevel,
        },
      },
      {
        Authorization: `Bearer ${token}`,
      }
    );
    if (res) {
      toggle();
      await settingsProfile();

      setTimeout(() => {
        toggle();
      }, 1500);
    }
  }
  return (
    <>
      <CustomFieldsWrapper>
        <ProfileSectionTitle>Custom Fields</ProfileSectionTitle>

        {dataSettingsProfile[0]?.operation_case_fields?.supervision_level && (
          <>
            <AccountSettingsHeader>
              <h2>Supervision Level</h2>
            </AccountSettingsHeader>
            <SupervisionLevel
              defaultValue={dataSettingsProfile[0].presets?.supervision_level}
              setSupervisionLevel={setSupervisionLevel}
            />
            <Button
              loading={loading}
              as="button"
              onClick={updateSupervisionLevel}
              text="Save"
            />
          </>
        )}

        {dataSettingsProfile[0]?.operation_case_fields?.urgency && (
          <>
            <AccountSettingsHeader>
              <h2>Urgency</h2>
            </AccountSettingsHeader>
            <UrgencyButtons
              settingsProfile={settingsProfile}
              data={dataSettingsProfile[0].presets?.urgency}
            />
          </>
        )}

        <AccountSettingsHeader>
          <h2>Custom Fields</h2>
        </AccountSettingsHeader>
        <CustomFieldsList
          settingsProfile={settingsProfile}
          dataSettingsProfile={dataSettingsProfile}
        />
      </CustomFieldsWrapper>
      <InformModal
        isOpen={show}
        title={"Supervision Level changed"}
        icon={
          <CheckOutlined
            style={{ fontSize: "24px", color: baseTheme.colors.main }}
          />
        }
      />
    </>
  );
}

export default CustomFieldsSettings;
