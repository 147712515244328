import Button from "components/Buttons/Button";
import React, { useState } from "react";
import styled from "styled-components";
import { baseTheme } from "styles/theme";

interface IModalComponent {
  onOk: () => void;
  showModal: boolean;
  firstScreenComponent: React.ReactNode;
  secondScreenComponent?: React.ReactNode;
}

export default function DescriptionModule({
  onOk,
  showModal,
  secondScreenComponent,
  firstScreenComponent,
}: IModalComponent) {
  const [isSecondScreen, setIsSecondScreen] = useState(false);
  const hasSecondScreen = !!secondScreenComponent;

  if (!showModal) return null;

  return (
    <Overlay>
      <ModalContent>
        <div>
          {isSecondScreen && hasSecondScreen
            ? secondScreenComponent
            : firstScreenComponent}
        </div>
        <ButtonContainer>
          {!isSecondScreen && hasSecondScreen ? (
            <Button
              bg={baseTheme.colors.main}
              color={baseTheme.colors.white}
              as="button"
              text="Next"
              onClick={() => setIsSecondScreen(true)}
            />
          ) : (
            <>
              <Button
                bg={baseTheme.colors.main}
                color={baseTheme.colors.white}
                as="button"
                onClick={onOk}
                text="Continue"
              />

              {hasSecondScreen && (
                <Button
                  br={baseTheme.colors.main}
                  bg={baseTheme.colors.transparent}
                  color={baseTheme.colors.main}
                  as="button"
                  text="Back"
                  onClick={() => setIsSecondScreen(false)}
                />
              )}
            </>
          )}
        </ButtonContainer>
      </ModalContent>
    </Overlay>
  );
}

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 4;
`;

const ModalContent = styled.div`
  background: #fff;
  border-radius: 24px;
  padding: 20px;
  width: 400px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ButtonContainer = styled.div`
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  justify-content: center;
`;
