// import CheckOutlined from "@ant-design/icons/CheckOutlined";
import Button from "components/Buttons/Button";
import Input from "components/Inputs/Input";
import Toast from "components/Toast";
import { getToken } from "helpers";
import { useAxios } from "hooks/useAxios";
import { useMemo, useState } from "react";
import { TitleSection } from "styles/components";
import { baseTheme } from "styles/theme";
import { ButtonWrapper, FormCustomField } from "./style";
import CustomBinaryField from "./CustomBinaryField";
import { IData } from "interfaces";

interface ISetBinaryField {
  getCustomFields: () => void;
  title: string;
  closeModal: (value: boolean) => void;
}



export default function SetBinaryField({
  getCustomFields,
  closeModal,
  title,
}: ISetBinaryField) {
  const [text, setText] = useState("");
  const token = getToken();

  const { request, loading, error } = useAxios();
  const [data, setData] = useState<IData | null>(null);
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value
      .replace(/[^a-zA-Z0-9 ]/g, "")
      .slice(0, 40);
    setText(inputValue);
  };

  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    const res = await request(
      "profile/custom-field",
      "POST",
      {
        name: text,
        type: "boolean",
      },
      {
        Authorization: `Bearer ${token}`,
      }
    );
    setData(res.data);
    getCustomFields();
  }

  const buttonDisabled = useMemo(() => {
    return !text;
  }, [text]);

  return (
    <>
      {!data && (
        <>
          <TitleSection>{title}</TitleSection>
          <FormCustomField onSubmit={handleSubmit}>
            <Input
              type="text"
              name="text"
              required={true}
              placeholder={"Enter Name"}
              value={text}
              onChange={handleInputChange}
            />
            <ButtonWrapper>
              <Button
                fontWeight={600}
                color={baseTheme.colors.white}
                fontSize="14px"
                disabled={buttonDisabled || loading}
                text="Save"
              />
            </ButtonWrapper>
          </FormCustomField>
        </>
      )}
      {data !== null && (
        <CustomBinaryField
          data={data}
          getCustomFields={getCustomFields}
          setData={setData}
          closeModal={closeModal}
        />
      )}
      {error && <Toast error={error} />}
    </>
  );
}
