import { getFromLocalStorageUser, IUserLStorage } from "helpers";
import React from "react";
import styled from "styled-components";
import { baseTheme } from "styles/theme";

function ContactDetails() {
  const user: IUserLStorage = getFromLocalStorageUser("user");
  const { email, first_name, last_name, phone } = user;

  const userDetails = [
    { title: "Name", text: first_name },
    { title: "Surname", text: last_name },
    { title: "Email", text: email },
    { title: "Phone", text: phone },
  ];

  return (
    <ContactDetailsContent>
      <AccountSettingsHeader>
        <h2>Contact details</h2>
      </AccountSettingsHeader>
      <DetailsContainer>
        {userDetails.map(({ title, text }, index) => (
          <DetailRow key={index}>
            <DetailTitle>{title}</DetailTitle>
            <DetailText>{text || "-"}</DetailText>
          </DetailRow>
        ))}
      </DetailsContainer>
    </ContactDetailsContent>
  );
}

export default ContactDetails;

const ContactDetailsContent = styled.div`
  width: 400px;
`;

export const AccountSettingsHeader = styled.div`
  display: flex;
  width: 100%;
  border-bottom: 1px solid #ddd;
  margin: 30px 0 20px;
  h2 {
    font-size: 18px;
    padding-bottom: 20px;
    text-align: left;
    font-weight: 600;
    color: ${baseTheme.colors.black};
    @media (max-width: 500px) {
      font-size: 1.1rem;
    }
    @media (min-width: 1921px) {
      font-size: 32px;
    }
  }
`;

const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: "100%";
`;

const DetailRow = styled.div`
  display: flex;
  justify-content: flex-start;
  width: "100%";
`;

const DetailTitle = styled.span`
  width: 50%;
`;

const DetailText = styled.span`
  color: #555;
  text-align: left;
`;
