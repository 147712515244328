import InformModal from "components/Modal/ConfirmModal";
import { getToken } from "helpers";
import { useAxios } from "hooks/useAxios";
import React, { useEffect, useReducer, useState } from "react";
import styled from "styled-components";
import { baseTheme } from "styles/theme";
import { FieldContainer, FormCustomField, SpinnerWrapper } from "./style";
import DeleteCustomField from "./DeleteCustomField";
import EditableField from "./EditableItem";
import Spinner from "components/Spinners/ButtonSpinner";
import { TitleSection } from "styles/components";
import Toast from "components/Toast";
import Input from "components/Inputs/Input";
import Button from "components/Buttons/Button";
import useCustomFieldStore from "store/customFieldId";

interface CustomOptionsFieldsProps {
  data: { id: string; name: string; "custom-field-values": [] };
  getCustomFields: () => void;
  setData?: any;
  closeModal: (isOpen: boolean) => void;
}

const CustomOptionsFields: React.FC<CustomOptionsFieldsProps> = ({
  data,
  getCustomFields,
  setData,
  closeModal,
}) => {
  const { request, error } = useAxios();
  const [showModal, toggleModal] = useReducer((showModal) => !showModal, false);
  const token = getToken();
  const [newOption, setNewOption] = useState("");
  const [options, setOptions] = useState<{ id: string; value: string }[]>([]);
  const [loader, setLoader] = useState(false);
  const { customFieldId, clearCustomFieldId } = useCustomFieldStore();
  useEffect(() => {
    if (data["custom-field-values"]) {
      setOptions(data["custom-field-values"]);
    }
  }, [data]);

  const handleAddOption = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoader(true);
    const res = await request(
      "profile/custom-field-value",
      "POST",
      {
        user_custom_fields_id: data.id,
        value: newOption,
      },
      {
        Authorization: `Bearer ${token}`,
      }
    );

    if (res) {
      setOptions((prev) => [...prev, { id: res.data.id, value: newOption }]);
      setNewOption("");
      if (res.data["custom-field-values"].length >= 2 && customFieldId) {
        clearCustomFieldId();
      }
      getCustomFields();
    }
    setLoader(false);
  };

  const handleDeleteOption = async (deleteItemId: string) => {
    setLoader(true);
    const res = await request(
      `profile/custom-field-value/${deleteItemId}/delete`,
      "GET",
      null,
      {
        Authorization: `Bearer ${token}`,
      }
    );

    if (res) {
      setOptions((prev) => prev.filter((option) => option.id !== deleteItemId));
      getCustomFields();
      clearCustomFieldId();
    }
    setLoader(false);
  };

  const onDelete = async () => {
    toggleModal();
    setLoader(true);
    const res = await request(
      `profile/custom-field/${data?.id}/delete`,
      "GET",
      null,
      {
        Authorization: `Bearer ${token}`,
      }
    );
    if (res.message === "Entity successful deleted.") {
      closeModal(false);
      await getCustomFields();
    }
    setLoader(false);
  };
  const handleSaveField = async (newValue: string) => {
    setLoader(true);
    const customFieldValues = options.map((option) => option.value);
    const res = await request(
      `profile/custom-field/${data.id}`,
      "POST",
      {
        name: newValue,
        type: "options",
        "custom-field-values": customFieldValues,
      },
      {
        Authorization: `Bearer ${token}`,
      }
    );
    setData(res.data);
    getCustomFields();
    setLoader(false);
  };

  const handleChangeOption = async (newValue: string, id?: string) => {
    setLoader(true);
    const updatedOptions = options.map((option) =>
      option.id === id ? { ...option, value: newValue } : option
    );

    setOptions(updatedOptions);

    const customFieldValues = updatedOptions.map((option) => option.value);

    const res = await request(
      `profile/custom-field/${data.id}`,
      "POST",
      {
        name: data.name,
        type: "options",
        "custom-field-values": customFieldValues,
      },
      {
        Authorization: `Bearer ${token}`,
      }
    );

    if (res) {
      setData(res.data);
      getCustomFields();
    }
    setLoader(false);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value
      .replace(/[^a-zA-Z0-9 ]/g, "")
      .slice(0, 40);
    setNewOption(inputValue);
  };

  return (
    <Container>
      <TitleSection>{`Edit ${data.name} field`}</TitleSection>
      {loader ? (
        <SpinnerWrapper>
          <Spinner color={baseTheme.colors.main} />
        </SpinnerWrapper>
      ) : (
        <>
          <EditableField
            showButton={false}
            id={data?.id}
            value={data.name}
            onSave={handleSaveField}
            onDelete={() => {}}
          />

          <Pt20></Pt20>
          <TitleSection>{`Edit ${data.name} field options`}</TitleSection>
          {options.length < 6 && (
            <FormCustomField onSubmit={handleAddOption}>
              <Input
                type="text"
                name="text"
                required={true}
                placeholder={"Add new custom field option"}
                value={newOption}
                onChange={handleInputChange}
              />
              <ButtonWrapper>
                <Button
                  fontWeight={600}
                  color={baseTheme.colors.white}
                  fontSize="14px"
                  disabled={data === null}
                  text="Save"
                />
              </ButtonWrapper>
            </FormCustomField>
          )}

          <OptionsContainer>
            {options.map(({ id, value }) => (
              <EditableField
                showButton={true}
                id={id}
                value={value}
                onSave={handleChangeOption}
                onDelete={handleDeleteOption}
              />
            ))}

            <FieldContainer>
              <DeleteCustomField name={data.name} onDelete={toggleModal} />
            </FieldContainer>
          </OptionsContainer>
        </>
      )}

      <InformModal
        isOpen={showModal}
        cancelButton={toggleModal}
        confirmButton={onDelete}
        title={"Please confirm deleting item from list"}
      />
      {error && <Toast error={error} />}
    </Container>
  );
};

export default CustomOptionsFields;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const InnerContainer = styled.div`
  width: 100%;
`;

export const ButtonWrapper = styled.div`
  margin-bottom: 16px;
`;

export const OptionContainer = styled.div`
  /* display: flex; */
  width: 100%;
  position: relative;
`;
export const RemoveButtonContainer = styled.div`
  position: absolute;
  top: 39%;
  left: 102%;
`;

export const RemoveButton = styled.button`
  position: absolute;
  right: 0;
  width: 30px;
  height: 30px;
  border-radius: 20px;
  background-color: ${baseTheme.colors.error};
  border-width: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const RemoveButtonText = styled.span`
  color: #efefef;
  font-size: 18px;
`;

export const OptionsContainer = styled.div`
  max-height: 200px;
  overflow-y: auto;
  margin-bottom: 16px;
  padding-right: 8px;
`;
const Pt20 = styled.div`
  padding-top: 20px;
`;
