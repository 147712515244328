import { IIcon } from "interfaces";

const CheckIcon = ({ color, width = 24, height = 24 }: IIcon) => {
  return (
    <div>
      <svg

        width={width}
        height={height}
        viewBox="0 0 256 256"
        fill="none"
      >
    
        <circle cx="128" cy="128" r="104" fill={color} />
   
        <path
          d="M177.53125,109.78906l-58.67187,56a8.02441,8.02441,0,0,1-11.0625,0l-29.32813-28a8.00675,8.00675,0,0,1,11.0625-11.57812l23.79687,22.72656,53.14063-50.72656a8.00675,8.00675,0,0,1,11.0625,11.57812Z"
          fill="#FFFFFF" 
        />
      </svg>
    </div>
  );
};

export default CheckIcon;
