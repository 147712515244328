import SubscriptionReports from "SubscriptionReports";
import ReportsComponent from "components/Reports";
import { IUserLStorage, getFromLocalStorageUser } from "helpers";
import MainLayout from "layouts/MainLayout";

export default function Reports() {
  const user: IUserLStorage = getFromLocalStorageUser("user");
  const { role } = user;

  return (
    <MainLayout
      children={
        <>
          {(role === "superadmin" || role === "admin") && (
            <SubscriptionReports />
          )}
          {role === "user" && <ReportsComponent />}
        </>
      }
    />
  );
}
