import { useState, useCallback } from "react";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import logOut from "helpers";
import { useNavigate } from "react-router-dom";
import AuthErrorStore from "store/AuthError";

export const useAxios = () => {
  const { setAuthError } = AuthErrorStore();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  function onLogOut() {
    logOut();
    navigate("/login");
  }
  const request = useCallback(
    async (
      url: string,
      method: AxiosRequestConfig["method"] = "get",
      data: any = null,
      headers: Record<string, string> = {},
      params: Record<string, any> = {}
    ): Promise<any> => {
      setLoading(true);
      setAuthError("");
      try {
        const response: AxiosResponse = await axios({
          method,
          url: `${process.env.REACT_APP_BASE_API}${url}`,
          data,
          params,
          headers: {
            "Content-Type": "application/json",
            ...headers, // Include custom headers
          },
        });
        setLoading(false);

        return response?.data;
      } catch (e: any) {
        if (
          e.response &&
          e.response.data.message === "Unauthenticated." &&
          e.response.status === 401
        ) {
          const errorMessage = "Your access session has expired. Please log in to continue.";
          setAuthError(errorMessage);
          onLogOut();
        }
        setError(
          e.response.data.message ||
            e.response.data.error ||
            "An error occurred"
        );
        setLoading(false);
      }
    },
    // eslint-disable-next-line
    []
  );

  const clearError = useCallback(() => setError(null), []);

  return { loading, request, error, clearError };
};
