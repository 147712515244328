import { ChangeEvent } from "react";
import { Section, StyledLabel } from "./styles";
import Input from "components/Inputs/Input";
import { LandingContent, useLandingContentStore } from "store/useLandingContentStore";

function Intro() {
  const { landingContent, updateField } = useLandingContentStore();

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    updateField(e.target.name as keyof LandingContent, e.target.value);
  };

  const inputFields = [
    { label: "Title intro", name: "introTitle", placeholder: "Enter title intro" },
    { label: "Sub title intro", name: "introFirstSubtext", placeholder: "Enter sub title intro" },
    { label: "Trial text intro", name: "introTrialText", placeholder: "Enter trial text intro" },
    { label: "Text intro", name: "introSecondSubtext", placeholder: "Enter Text intro" },
  ] as const;

  return (
    <Section>
      {inputFields.map(({ label, name, placeholder }) => (
        <div key={name}>
          <StyledLabel>{label}</StyledLabel>
          <Input
            placeholder={placeholder}
            name={name}
            value={landingContent[name as keyof LandingContent] as string || ''}
            onChange={handleInputChange}
          />
        </div>
      ))}
    </Section>
  );
}

export default Intro;
