import { IIcon } from "interfaces";

const PrivacyPolicyIcon = ({ color, width = 28, height = 34 }: IIcon) => {
  return (
    <div style={{ width: width, height: height }}>
      <svg
        version="1.1"
  
        viewBox="0 0 90 112.5"
     
      >
        <path
        
          fill={color}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M41.02,10.266c1.787,0,3.234,1.495,3.234,3.333c0,1.839-1.447,3.329-3.234,3.329  c-1.785,0-3.234-1.49-3.234-3.329C37.785,11.761,39.234,10.266,41.02,10.266z M41.02,4.833c-3.691,0.006-6.969,2.433-8.119,6.025  l-15.365,0.027c-3.369,0-6.281,2.52-6.281,5.802v62.678c0,3.281,2.912,5.802,6.281,5.802h46.971c3.369,0,6.281-2.521,6.281-5.802  v-9.49l-3.031,2.421v7.068c0,1.48-1.34,2.787-3.25,2.787H17.535c-1.906,0-3.254-1.307-3.254-2.787V16.688  c0-1.479,1.348-2.787,3.254-2.787h7.078v5.49c0,1.427,1.168,2.578,2.609,2.578h27.594c1.443,0,2.605-1.15,2.605-2.578v-5.49h7.084  c1.91,0,3.25,1.308,3.25,2.787v12.828l3.031,1.052v-13.88c0-3.282-2.912-5.802-6.281-5.802l-15.371-0.027  C47.984,7.271,44.707,4.839,41.02,4.833z"
        />
        <path
          fill={color}
          d="M58.787,41.678c2.723,0,4.932,2.307,4.932,5.15c-0.006,1.703-0.813,3.296-2.166,4.25l1.473,6.297  c0.115,0.688-0.391,1.266-0.885,1.266h-6.709c-0.49,0-1-0.578-0.885-1.266l1.473-6.287c-1.348-0.959-2.16-2.552-2.16-4.26  C53.859,43.985,56.063,41.678,58.787,41.678z M58.787,29.266l-19.996,6.948v7.547c0,15.386,8.025,24.651,19.996,30.989  c11.973-6.338,20-15.604,20-30.989v-7.547L58.787,29.266z"
        />
        <path
          fill={color}
          d="M20.572,34.698c-2.016,0-2.016,3.026,0,3.026h12.068c2.02,0,2.02-3.026,0-3.026H20.572z"
        />
        <path
          fill={color}
          d="M20.572,41.526c-2.016,0-2.016,3.027,0,3.027h12.068c2.02,0,2.02-3.027,0-3.027H20.572z"
        />
        <path
          fill={color}
          d="M20.426,48.354c-1.957,0.152-1.813,3.063,0.146,3.021h12.068c0.869,0.047,1.598-0.641,1.598-1.51  c0-0.87-0.729-1.558-1.598-1.511H20.572C20.525,48.354,20.473,48.354,20.426,48.354z"
        />
        <path
          fill={color}
          d="M20.572,55.178c-2.016,0-2.016,3.025,0,3.025h12.068c2.02,0,2.02-3.025,0-3.025H20.572z"
        />
        <path
          fill={color}
          d="M20.426,62.006c-1.957,0.146-1.813,3.058,0.146,3.015h17.031c0.865,0.052,1.594-0.64,1.594-1.505  c0-0.869-0.729-1.561-1.594-1.509H20.572C20.525,62,20.473,62,20.426,62.006z"
        />
        <path
          fill={color}
          d="M20.572,68.824c-2.016,0-2.016,3.025,0,3.025h20.391c2.016,0,2.016-3.025,0-3.025H20.572z"
        />
      </svg>
    </div>
  );
};

export default PrivacyPolicyIcon;
