import { IIcon } from "interfaces";

const Delete = ({ color, width = 20, height = 20 }: IIcon) => {
  return (
    <div style={{ width: width, height: height, paddingRight: "5px" }}>
      <svg
       
        width="20"
        height="20"
        viewBox="0 0 24 24"
        fill="#E02B00"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
   
      >
        <circle color="#fff" cx="12" cy="12" r="10" />
        <path color="#fff"  d="m15 9-6 6" />
        <path color="#fff" d="m9 9 6 6" />
      </svg>
    </div>
  );
};

export default Delete;
