import React, { useEffect, useState } from "react";
import styled from "styled-components";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import "components/TextEditor/style.css";
import {
  EditorState,
  ContentState,
  convertToRaw,
  convertFromHTML,
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import Button from "components/Buttons/Button";
import { useAxios } from "hooks/useAxios";
import { baseTheme } from "styles/theme";
import { getToken } from "helpers";
import draftToHtml from "draftjs-to-html";
import { TitleTab } from "components/DataComponent";

const Wrapper = styled.div`
  display: flex;

  padding: 50px 20px;
  max-width: 800px;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  position: relative;
  z-index: 0;
  align-items: center;
`;

const ButtonWrapper = styled.div`
  width: 150px;
  margin: 0 auto;
  padding: 40px 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;
interface ITextEditor {
  route: string;
  title: string;
}

function TextEditor({ route, title }: ITextEditor) {
  const { request, loading } = useAxios();
  const [privacyPolicy, setPrivacyPolicy] = useState();
  const token = getToken();
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  useEffect(() => {
    getData();
    if (privacyPolicy) {
      const contentBlocks = convertFromHTML(privacyPolicy);
      const contentState = ContentState.createFromBlockArray(
        contentBlocks.contentBlocks,
        contentBlocks.entityMap
      );
      const newEditorState = EditorState.createWithContent(contentState);
      setEditorState(newEditorState);
    }
    // eslint-disable-next-line
  }, [privacyPolicy]);

  async function getData() {
    const type =
      route === "privacy-policy" ? "privacy-policy" : "terms-and-conditions";
    const res = await request(`${type}`, "GET", null);
    setPrivacyPolicy(res.value);
  }

  async function handleSave() {
    const value = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    await request(
      `admin/${route}`,
      "POST",
      { value },
      {
        Authorization: `Bearer ${token}`,
      }
    );
    getData();
  }

  const handleEditorStateChange = (newEditorState: any) => {
    setEditorState(newEditorState);
  };

  return (
    <>
      <Wrapper>

        <TitleTab>{title}</TitleTab>
        <div style={{padding:'5px 0'}}></div>
        {editorState && (
          <Editor
            editorState={editorState}
            onEditorStateChange={handleEditorStateChange}
            wrapperClassName="demo-wrapper"
            editorClassName="editor-class"
          />
        )}
      </Wrapper>
      <ButtonWrapper>
        <Button
          onClick={handleSave}
          color={baseTheme.colors.white}
          text="Save"
          loading={loading}
        />
      </ButtonWrapper>
    </>
  );
}
export default TextEditor;
