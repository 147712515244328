import Select from "components/Select";
import React from "react";
import supervisionLevelOptions from "data/supervisionLevel";

interface ISupervisionLevel {
  setSupervisionLevel: (value: string) => void;
  defaultValue: string;
}

function SupervisionLevel({
  setSupervisionLevel,
  defaultValue,
}: ISupervisionLevel) {
  return (
    <>
      <Select
        color="#000"
        placeholder={defaultValue || "Select Supervision Level"}
        options={supervisionLevelOptions as any}
        onChange={(value: string | number) =>
          setSupervisionLevel(value.toString())
        }
      />
    </>
  );
}

export default SupervisionLevel;
