import Modal from "components/Modal/Modal";
import { baseTheme } from "styles/theme";

import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

import Warn from "assets/icons/Warn";
import { getToken } from "helpers";
import { useAxios } from "hooks/useAxios";
import Button from "components/Buttons/Button";

const Container = styled.div`
  max-width: 450px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;

`;

const ButtonWrap = styled.div`
  width: 100%;
  padding-top: 20px;
`;

const Text = styled.p`
  text-align: center;
  font-size: 15px;
  line-height: 1.7;
  font-family: "Poppins", sans-serif;
  color: #333;
  margin: 5px 0;
`;

const Separator = styled.div`
  margin: 10px 0;
`;

interface IDeleteUserAccount {
  isOpen: boolean;
  onRequestClose: () => void;
  id: string | null;
}

interface IDeleteCaseLogComponent {
  onRequestClose: () => void;
  handleDelete: () => void;
}

const DeleteCaseLogComponent = ({
  onRequestClose,
  handleDelete,
}: IDeleteCaseLogComponent) => {
  return (
    <Container>
      <Text>
        You are about to delete a case log. This action cannot be reverted.
        Please confirm you would like to delete the case log.
      </Text>
      <ButtonWrap>
        <Button
          br={baseTheme.colors.error}
          bg={"transparent"}
          as="button"
          color={baseTheme.colors.error}
          onClick={handleDelete}
          text="Delete"
        />
        <Separator />
        <Button
          br={baseTheme.colors.main}
          bg={"transparent"}
          as="button"
          color={baseTheme.colors.main}
          onClick={onRequestClose}
          text="Cancel"
        />
      </ButtonWrap>
    </Container>
  );
};

export default function DeleteCaseLog({
  isOpen,
  onRequestClose,
  id,
}: IDeleteUserAccount) {
  const { request } = useAxios();

  const navigate = useNavigate();
  const token = getToken();

  const handleDelete = async () => {
    await request(`operation-case/${id}/delete`, "GET", null, {
      Authorization: `Bearer ${token}`,
    });
    onRequestClose();
    navigate(-1);
  };

  return (
    <Modal
      icon={<Warn color={baseTheme.colors.error} />}
      isOpen={isOpen}
      text=""
      children={
        <DeleteCaseLogComponent
          handleDelete={handleDelete}
          onRequestClose={onRequestClose}
        />
      }
      title={"Deleting the case log"}
    ></Modal>
  );
}
