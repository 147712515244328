import Toast from "components/Toast";
import { getToken } from "helpers";
import { useAxios } from "hooks/useAxios";
import { useEffect, useMemo, useReducer, useState } from "react";
import { styled } from "styled-components";
import { baseTheme } from "styles/theme";
import Spinner from "components/Spinners/ButtonSpinner";
import Button from "components/Buttons/Button";
import Input from "components/Inputs/Input";
import {
  Container,
  FieldContainer,
  FormCustomField,
  SpinnerWrapper,
} from "./style";
import { TitleCustomSection, TitleSection } from "styles/components";
import { IData } from "interfaces";
import EditableField from "./EditableItem";
import DeleteCustomField from "./DeleteCustomField";
import InformModal from "components/Modal/ConfirmModal";
import useCustomFieldStore from "store/customFieldId";

interface ISetToggleField {
  getCustomFields: () => void;
  title: string;
  closeModal: (value: boolean) => void;
}
interface IOptionsContainer {
  isEdit?: boolean;
}
const ButtonWrapper = styled.div`
  padding-bottom: 10px;
  width: 100%;
`;
const Pt10 = styled.div`
  padding-top: 10px;
`;
export const OptionsContainer = styled.div<IOptionsContainer>`
  max-height: ${(props) => (props.isEdit ? "170px" : "300px")};
  overflow-y: auto;
  margin-bottom: 16px;
  padding-right: 8px;
`;
function SetToggleField({
  getCustomFields,
  title,
  closeModal,
}: ISetToggleField) {
  const [text, setText] = useState("");
  const token = getToken();
  const { setCustomFieldId, customFieldId, clearCustomFieldId } =
    useCustomFieldStore();
  const [showModal, toggleModal] = useReducer((showModal) => !showModal, false);
  const { request, loading, error } = useAxios();
  const [data, setData] = useState<IData | null>(null);
  const [option, setOption] = useState("");
  const [options, setOptions] = useState<{ id: string; value: string }[]>([]);
  const [loader, setLoader] = useState(false);
  useEffect(() => {
    if (data !== null) {
      if (data["custom-field-values"]) {
        setOptions(data["custom-field-values"]);
      }
    }
  }, [data]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value
      .replace(/[^a-zA-Z0-9 ]/g, "")
      .slice(0, 40);
    setText(inputValue);
  };

  const handleInputChangeOpt = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value
      .replace(/[^a-zA-Z0-9 ]/g, "")
      .slice(0, 40);
    setOption(inputValue);
  };

  const buttonDisabled = useMemo(() => {
    return text !== "";
  }, [text]);

  const btnOptionDisabled = useMemo(() => {
    return data !== null && option !== "";
  }, [data, option]);

  const handleNameField = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoader(true);
    const res = await request(
      "profile/custom-field",
      "POST",
      {
        name: text,
        type: "toggle",
      },
      {
        Authorization: `Bearer ${token}`,
      }
    );

    if (res.data) {
      setData(res.data);
      setCustomFieldId(res.data.id);
      await getCustomFields();
    }
    setLoader(false);
  };

  const handleOptionsField = async (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();
    setLoader(true);

    const customFieldValues =
      options.length === 0
        ? [option]
        : [...options.map((option) => option.value), option];

    const res = await request(
      `profile/custom-field/${data?.id}`,
      "POST",
      {
        name: data?.name,
        type: "toggle",
        "custom-field-values": customFieldValues,
      },
      {
        Authorization: `Bearer ${token}`,
      }
    );

    if (res.data) {
      setData(res.data);
      setOptions(res.data["custom-field-values"]);
      if (res.data["custom-field-values"].length === 2 && customFieldId) {
        clearCustomFieldId();
      } else {
        setCustomFieldId(res.data.id);
      }
      setOption("");
      await getCustomFields();
    }
    setLoader(false);
  };

  const handleChangeName = async (newValue: string, id?: string) => {
    setLoader(true);
    const customFieldValues = options.map((option) => option.value);

    const dataRequest = !options
      ? {
          name: newValue,
          type: "toggle",
        }
      : {
          name: newValue,
          type: "toggle",
          "custom-field-values": customFieldValues,
        };

    const res = await request(
      `profile/custom-field/${data?.id}`,
      "POST",
      dataRequest,
      {
        Authorization: `Bearer ${token}`,
      }
    );

    if (res) {
      setData(res.data);
      getCustomFields();
    }
    setLoader(false);
  };
  const handleChangeOption = async (newValue: string, id?: string) => {
    setLoader(true);
    const updatedOptions = options.map((option) =>
      option.id === id ? { ...option, value: newValue } : option
    );

    const customFieldValues = updatedOptions.map((option) => option.value);

    const res = await request(
      `profile/custom-field/${data?.id}`,
      "POST",
      {
        name: data?.name,
        type: "toggle",
        "custom-field-values": customFieldValues,
      },
      {
        Authorization: `Bearer ${token}`,
      }
    );

    if (res) {
      if (res.data["custom-field-values"].length >= 2 && customFieldId) {
        clearCustomFieldId();
      }

      setData(res.data);
      getCustomFields();
    }
    setLoader(false);
  };
  const onDelete = async () => {
    toggleModal();
    setLoader(true);
    const res = await request(
      `profile/custom-field/${data?.id}/delete`,
      "GET",
      null,
      {
        Authorization: `Bearer ${token}`,
      }
    );
    if (res.message === "Entity successful deleted.") {
      closeModal(false);
      await getCustomFields();
      clearCustomFieldId();
    }
    setLoader(false);
  };
  return (
    <Container>
      <>
        <TitleSection>{title}</TitleSection>
        {loader ? (
          <SpinnerWrapper>
            <Spinner color={baseTheme.colors.main} />
          </SpinnerWrapper>
        ) : (
          <>
            {data ? (
              <>
                <TitleCustomSection>{`Edit ${data.name} field`}</TitleCustomSection>
                <EditableField
                  showButton={false}
                  id={data?.id}
                  value={data.name}
                  onSave={handleChangeName}
                  onDelete={() => {}}
                />
              </>
            ) : (
              <>
                <TitleCustomSection>{`Toggle custom field name`}</TitleCustomSection>

                <FormCustomField onSubmit={handleNameField}>
                  <Input
                    type="text"
                    name="text"
                    required={true}
                    placeholder={"Enter Name"}
                    value={text}
                    onChange={handleInputChange}
                  />
                  <ButtonWrapper>
                    <Button
                      fontWeight={600}
                      color={baseTheme.colors.white}
                      fontSize="14px"
                      disabled={!buttonDisabled || loading}
                      text="Save"
                    />
                  </ButtonWrapper>
                </FormCustomField>
              </>
            )}
            {options.length < 2 && (
              <>
                <Pt10 />
                <TitleCustomSection>{`Toggle custom field options`}</TitleCustomSection>

                <FormCustomField onSubmit={handleOptionsField}>
                  <Input
                    type="text"
                    name="text"
                    required={true}
                    placeholder={"Enter Name"}
                    value={option}
                    onChange={handleInputChangeOpt}
                  />
                  <ButtonWrapper>
                    <Button
                      fontWeight={600}
                      color={baseTheme.colors.white}
                      fontSize="14px"
                      disabled={!btnOptionDisabled || loading}
                      text="Save"
                    />
                  </ButtonWrapper>
                </FormCustomField>
              </>
            )}
            <OptionsContainer isEdit={options.length < 2}>
              {(options?.[0] || options?.[1]) && (
                <TitleCustomSection>{`Edit ${data?.name} options field`}</TitleCustomSection>
              )}

              <>
                {options?.[0] && (
                  <EditableField
                    showButton={false}
                    id={options[0].id}
                    value={options[0].value}
                    onSave={handleChangeOption}
                    onDelete={() => {}}
                  />
                )}
                {options?.[1] && (
                  <EditableField
                    showButton={false}
                    id={options[1].id}
                    value={options[1].value}
                    onSave={handleChangeOption}
                    onDelete={() => {}}
                  />
                )}
              </>
              {data !== null && (
                <FieldContainer>
                  <DeleteCustomField name={data?.name} onDelete={toggleModal} />
                </FieldContainer>
              )}
            </OptionsContainer>
          </>
        )}
      </>
      <InformModal
        isOpen={showModal}
        cancelButton={toggleModal}
        confirmButton={onDelete}
        title={"Please confirm deleting item from list"}
      />
      {error && <Toast error={error} />}
    </Container>
  );
}

export default SetToggleField;
