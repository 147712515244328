import { useReducer, useState } from "react";
import { styled } from "styled-components";
import { AccountSettingsHeader } from "./AccountDetails";
import { baseTheme } from "styles/theme";
import ProfileUserinfo from "./ProfileUserinfo";
import {
  getFromLocalStorageUser,
  getStatusMember,
  getToken,
  IUserLStorage,
} from "helpers";
import { useAxios } from "hooks/useAxios";
import DeleteUserAccountModal from "pages/Profile/components/DeleteUserAccount";
import ContactDetails from "./AccountDetails";
import Select from "components/Select";
import Button from "components/Buttons/Button";
import { ModalContainer } from "./SubscriptionManager/SubscriptionManager";
import InformModal from "components/Modal/ConfirmModal";
import CheckOutlined from "@ant-design/icons/CheckOutlined";

function translateData(data: string) {
  switch (data) {
    case "set-trainee":
      return "SET trainee";
    case "consultant":
      return "consultant";
    case "unaccredited-trainee":
      return "unaccredited trainee";
    default:
      return "Invalid input";
  }
}

const userTypeOptions = [
  { value: "consultant", label: "consultant" },
  { value: "set-trainee", label: "SET trainee" },
  { value: "unaccredited-trainee", label: "unaccredited trainee" },
];

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`;

export const ProfileSectionTitle = styled.div`
  font-size: 20px;
  margin: 20px 0;
  text-align: left;
  font-weight: 600;
  color: ${baseTheme.colors.black};
  @media (max-width: 500px) {
    font-size: 1.1rem;
  }
  @media (min-width: 1921px) {
    font-size: 32px;
  }
`;

interface IAccountSettings {
  settingsProfile: () => void;
  getUserInfo: () => void;
}

function AccountSettings({ settingsProfile, getUserInfo }: IAccountSettings) {
  const { request } = useAxios();
  const [show, toggle] = useReducer((show) => !show, false);
  const [userType, setUserType] = useState("");
  const [subsLoading, setSubsLoading] = useState(false);
  const token = getToken();
  const statusMember = getStatusMember();
  const user: IUserLStorage = getFromLocalStorageUser("user");
  const { type, role } = user;
  const [subscriptionText, setSubscriptionText] = useState("");
  const [showSubsModal, toggleSubsModal] = useReducer(
    (showSubsModal) => !showSubsModal,
    false
  );
  const [showDeleteModal, toggleDeleteModal] = useReducer(
    (showDeleteModal) => !showDeleteModal,
    false
  );
  const [loading, setLoading] = useState(false);
  async function ChangeUserType() {
    setLoading(true);
    const res = await request(
      "profile/type-speciality",
      "POST",
      {
        type: userType,
        speciality: "lol",
      },
      {
        Authorization: `Bearer ${token}`,
      }
    );
    setLoading(false);
    if (res) {
      toggle();
      await getUserInfo();
      await settingsProfile();
      setTimeout(() => {
        toggle();
      }, 1500);
    }
  }
  async function unSubscribe() {
    // toggleSubManager();
    setSubsLoading(true);
    const res = await request(
      "stripe/cancel-subscription-at-period-end",
      "GET",
      null,
      {
        Authorization: `Bearer ${token}`,
      }
    );
    toggleSubsModal()
    setSubscriptionText(res.data);
    setSubsLoading(false);
  }

  async function confirmHandlePayment() {
    if (statusMember === "subscribed") {
      unSubscribe();
      return;
    }

    if (statusMember === "demo" || "demo-expired" || "subscribe-expired") {
      setSubsLoading(true);
      const res = await request("stripe/generate-payment-link", "GET", null, {
        Authorization: `Bearer ${token}`,
      });
      if (res) {
        window.location.href = res.url;
        // toggleSubManager();
      }
      setLoading(false);
    }
  }

  return (
    <Wrapper>
      {role === "superadmin" ? (
        <>
          <ProfileSectionTitle>Account Settings</ProfileSectionTitle>
          <ProfileUserinfo settingsProfile={getUserInfo} />
          <ContactDetails />
        </>
      ) : (
        <>
          <ProfileSectionTitle>Account Settings</ProfileSectionTitle>
          <ProfileUserinfo settingsProfile={settingsProfile} />
          <ContactDetails />
          <div style={{ width: "400px", marginBottom: "35px" }}>
            <AccountSettingsHeader>
              <h2>User Type</h2>
            </AccountSettingsHeader>
            <Select
              placeholder={translateData(type)}
              options={userTypeOptions as any}
              onChange={(value: string | number) =>
                setUserType(value.toString())
              }
            />
            <Button
              fontWeight={600}
              color={baseTheme.colors.white}
              fontSize="14px"
              onClick={ChangeUserType}
              loading={loading}
              text="Save"
            />
            <AccountSettingsHeader>
              <h2>Subscription</h2>
            </AccountSettingsHeader>
            <ModalContainer
              subsLoading={subsLoading}
              confirmHandlePayment={confirmHandlePayment}
            />
            <AccountSettingsHeader>
              <h2>Delete Account</h2>
            </AccountSettingsHeader>
            <Button
              bg={baseTheme.colors.transparent}
              color={baseTheme.colors.error}
              br={baseTheme.colors.error}
              onClick={toggleDeleteModal}
              text="Delete Account"
            />
          </div>
        </>
      )}
      <DeleteUserAccountModal
        isOpen={showDeleteModal}
        onRequestClose={toggleDeleteModal}
      />
      <InformModal
        isOpen={show}
        title={"User Type changed"}
        icon={
          <CheckOutlined
            style={{ fontSize: "24px", color: baseTheme.colors.main }}
          />
        }
      />
      <InformModal
        isOpen={showSubsModal}
        title={subscriptionText}
        confirmButton={toggleSubsModal}
        icon={
          <CheckOutlined
            style={{ fontSize: "24px", color: baseTheme.colors.main }}
          />
        }
      />
    </Wrapper>
  );
}

export default AccountSettings;
