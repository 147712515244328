import Button from "components/Buttons/Button";
import React, { ReactNode } from "react";
import styled, { keyframes } from "styled-components";
import { TitleSection } from "styles/components";
import { baseTheme } from "styles/theme";

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;
const RoundBg = styled.div`
  width: 80px;
  height: 80px;
  margin: 10px 0;
  background-color: ${baseTheme.colors.gray};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 4;
`;
const SubTitle = styled.p`
  color: #838383;
  padding-bottom: 10px;
  line-height: 17px;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
`;

const ModalContainer = styled.div`
  background: #fff;
  border: 1px solid #ccc;
  padding: 20px;
  border-radius: 24px;
  animation: ${fadeIn} 0.3s ease;
  display: flex;
  width: 300px;
  flex-direction: column;
  align-items: center;
`;
const ButtonWrapper = styled.div`
  padding-bottom: 10px;
  width: 100%;
`;

interface ModalProps {
  isOpen: boolean;
  icon?: ReactNode;
  title: string;
  subTitle?: string;
  textButton?: string;
  confirmButton?: () => void;
  cancelButton?: () => void;
}

const InformModal: React.FC<ModalProps> = ({
  icon,
  isOpen,
  confirmButton,
  title,
  subTitle,
  cancelButton,
  textButton = "Continue",
}) => {
  if (!isOpen) {
    return null;
  }

  return (
    <Overlay>
      <ModalContainer>
        {icon && <RoundBg>{icon}</RoundBg>}

        <TitleSection>{title}</TitleSection>
        {subTitle && <SubTitle>{subTitle}</SubTitle>}

        <br />
        {confirmButton && (
          <ButtonWrapper>
            <Button onClick={confirmButton} text={textButton} />
          </ButtonWrapper>
        )}
        {cancelButton && (
          <ButtonWrapper>
            <Button
              br={baseTheme.colors.main}
              as="button"
              bg={baseTheme.colors.white}
              color={baseTheme.colors.main}
              onClick={cancelButton}
              text="Cancel"
            />
          </ButtonWrapper>
        )}
      </ModalContainer>
    </Overlay>
  );
};

export default InformModal;
