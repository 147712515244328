import Input from "components/Inputs/Input";
import {
  ButtonWrapper,
  DeleteIcon,
  FAQItem,
  Section,
  StyledLabel,
} from "./styles";
import Button from "components/Buttons/Button";
import {
  FAQ,
  useLandingContentStore,
} from "store/useLandingContentStore";
import TextArea from "components/TextArea";

import Delete from "assets/icons/Delete";
import { baseTheme } from "styles/theme";
import { useAxios } from "hooks/useAxios";
import { getToken } from "helpers";

function FAQSection() {
  const { landingContent, updateFaq, setLandingContent } =
    useLandingContentStore();
  const { request } = useAxios();
  const token = getToken();
  const addFaq = () => {
    const newFaq = { question: "", answer: "", questionId: "", answerId: "" };
    setLandingContent({
      ...landingContent,
      faq: [...landingContent.faq, newFaq],
    });
  };

  const removeFaq = async (index: number, item: FAQ) => {
    try {
      if (item.questionId) {
        await request(
          `admin/web-content/${item.questionId}/delete`,
          "GET",
          null,
          {
            Authorization: `Bearer ${token}`,
          }
        );
      }

      if (item.answerId) {
        await request(
          `admin/web-content/${item.answerId}/delete`,
          "GET",
          null,
          {
            Authorization: `Bearer ${token}`,
          }
        );
      }

      const updatedFaq = landingContent.faq.filter((_, i) => i !== index);
      setLandingContent({ ...landingContent, faq: updatedFaq });
    } catch (error) {
      console.error("Failed to remove FAQ:", error);
    }
  };

  return (
    <Section>
      {landingContent.faq.map((item: FAQ, index: number) => (
        <FAQItem key={index}>
          <DeleteIcon onClick={() => removeFaq(index, item)}>
            <Delete />
          </DeleteIcon>
          <StyledLabel>FAQ title</StyledLabel>
          <Input
            placeholder="Enter question"
            value={item.question}
            onChange={(e) => updateFaq(index, "question", e.target.value)}
          />
          <StyledLabel>FAQ text</StyledLabel>
          <TextArea
            name={`faq[${index}].answer`}
            placeholder="Enter answer"
            value={item.answer}
            onChange={(e) => updateFaq(index, "answer", e.target.value)}
          />
        </FAQItem>
      ))}

      <ButtonWrapper>
        <Button
          bg={baseTheme.colors.transparent}
          color={baseTheme.colors.primary}
          br={baseTheme.colors.primary}
          text="Add new FAQ"
          onClick={addFaq}
        />
      </ButtonWrapper>
    </Section>
  );
}

export default FAQSection;
