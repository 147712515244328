import React from "react";
import { Routes, Route } from "react-router-dom";
import LandingPage from "pages/landing/LandingPage";
import LoginPage from "pages/LoginPage";
import ResetPassword from "pages/ResetPassword";
import TwoStepPage from "pages/TwoStepPage";
import RegisterPage from "pages/RegisterPage";
import ErrorPage from "pages/Error";
import Profile from "pages/Profile/Profile";
import PaymentSuccess from "pages/Payment/PaymentSuccess";
import ReviewCasePage from "pages/ReviewCasePage";
import ChangePassword from "pages/ChangePassword";
import TermsConditions from "pages/TermsConditions";
import PrivacyPolicies from "pages/PrivacyPolicies";
import EditPrivacyPolicies from "pages/EditPrivacyPolicies";
import EditTermsConditions from "pages/EditTermsConditions";
import UserSettingsPage from "pages/UserSettingsPage/UserSettingsPage";
import PaymentCanceled from "pages/Payment/PaymentCanceled";
import CaseLogsPage from "pages/CaseLogsPage";
import ComplicationsPage from "pages/ComplicationsPage";
import PathologiesСheckedPage from "pages/PathologiesСheckedPage";
import Reports from "pages/Reports";
import PageUsers from "pages/PageUsers";
import PathologiesPendingPage from "pages/PathologiesPendingPage";
import PrivateRoute from "./navigation/PrivateRoute";
import PublicRoute from "./navigation/PublicRoute";
import OperationNotesPages from "pages/OperationNotesPages";
import ConfirmAccount from "pages/ConfirmAccount";
import ReviewUserLogsPage from "pages/ReviewUsersLogPage";
import UsersManagmentPage from "pages/UsersManagmentPage";
import ContentManagementPage from "pages/ContentManagementPage";

export enum Role {
  admin = "admin",
  superadmin = "superadmin",
  user = "user",
}

export default function Router() {
  return (
    <Routes>
      <Route path="/" element={<LandingPage />} />

      <Route
        path="/login"
        element={
          <PublicRoute restricted>
            <LoginPage />
          </PublicRoute>
        }
      />
      <Route
        path="/register"
        element={
          <PublicRoute restricted>
            <RegisterPage />
          </PublicRoute>
        }
      />
      <Route
        path="/user-settings"
        element={
          <PrivateRoute roles={[Role.user, Role.superadmin, Role.admin]}>
            <UserSettingsPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/resetpsw"
        element={
          <PublicRoute restricted>
            <ResetPassword />
          </PublicRoute>
        }
      />
      <Route
        path="/change-password/:token"
        element={
          <PublicRoute restricted>
            <ChangePassword />
          </PublicRoute>
        }
      />
      <Route
        path="/verification"
        element={
          <PublicRoute restricted>
            <TwoStepPage />
          </PublicRoute>
        }
      />
      <Route path="/terms-and-conditions" element={<TermsConditions />} />
      <Route path="/privacy-policy" element={<PrivacyPolicies />} />
      <Route
        path="/review-user-logs"
        element={
          <PrivateRoute roles={[Role.superadmin, Role.admin]}>
            <ReviewUserLogsPage />
          </PrivateRoute>
        }
      />

      <Route
        path="/notes"
        element={
          <PrivateRoute roles={[Role.user, Role.superadmin, Role.admin]}>
            <OperationNotesPages />
          </PrivateRoute>
        }
      />
      <Route
        path="/users"
        element={
          <PrivateRoute roles={[Role.superadmin, Role.admin]}>
            <PageUsers />
          </PrivateRoute>
        }
      />
      <Route
        path="/case-logs"
        element={
          <PrivateRoute roles={[Role.user]}>
            <CaseLogsPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/complications"
        element={
          <PrivateRoute roles={[Role.user]}>
            <ComplicationsPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/pathologies-pending"
        element={
          <PrivateRoute roles={[Role.user]}>
            <PathologiesPendingPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/pathologies-checked"
        element={
          <PrivateRoute roles={[Role.user]}>
            <PathologiesСheckedPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/profile"
        element={
          <PrivateRoute roles={[Role.superadmin, Role.admin, Role.user]}>
            <Profile />
          </PrivateRoute>
        }
      />
      <Route
        path="/users-managment"
        element={
          <PrivateRoute roles={[Role.superadmin, Role.admin]}>
            <UsersManagmentPage />
          </PrivateRoute>
        }
      />

      <Route
        path="/reports"
        element={
          <PrivateRoute roles={[Role.superadmin, Role.admin, Role.user]}>
            <Reports />
          </PrivateRoute>
        }
      />
      <Route
        path="/payment/success"
        element={
          <PrivateRoute roles={[Role.superadmin, Role.admin, Role.user]}>
            <PaymentSuccess />
          </PrivateRoute>
        }
      />
      <Route
        path="/payment/cancel"
        element={
          <PrivateRoute roles={[Role.superadmin, Role.admin, Role.user]}>
            <PaymentCanceled />
          </PrivateRoute>
        }
      />
      <Route
        path="/review"
        element={
          <PrivateRoute roles={[Role.user, Role.superadmin, Role.admin]}>
            <ReviewCasePage />
          </PrivateRoute>
        }
      />
      <Route
        path="/edit-privacy-policy"
        element={
          <PrivateRoute roles={[Role.superadmin]}>
            <EditPrivacyPolicies />
          </PrivateRoute>
        }
      />
      <Route
        path="/content-management"
        element={
          <PrivateRoute roles={[Role.superadmin]}>
            <ContentManagementPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/edit-terms-condition"
        element={
          <PrivateRoute roles={[Role.superadmin]}>
            <EditTermsConditions />
          </PrivateRoute>
        }
      />
      <Route
        path="/confirm-account"
        element={
          <PublicRoute>
            <ConfirmAccount />
          </PublicRoute>
        }
      />
      <Route path="*" element={<ErrorPage />} />
    </Routes>
  );
}
