import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { baseTheme } from "styles/theme";

interface IStyledCustomSelect {
  isOpDetails?: boolean;
  isEditField?: boolean;
}

const StyledInputWrap = styled.div<IStyledCustomSelect>`
  display: flex;
  position: relative;
  flex-direction: column;
  margin-bottom: ${(props) => (props.isEditField ? 0 : "20px")};
  width: ${(props) => (props.isEditField ? "40%" : "100%")};
  position: relative;
`;

const StyledLabel = styled.label`
  padding-bottom: 0.5rem;
  font-size: 0.75rem;
  font-weight: bold;
`;

export interface Option {
  label: string;
  value: number;
}

interface SelectProps {
  label?: string;
  options: Option[];
  onChange: (value: number) => void;
  isOpDetails?: boolean;
  placeholder?: string;
  isEditField?: boolean;
  color?: string;
}

const StyledSelectWrapper = styled.div`
  position: relative;
  width: 100%;
  z-index: 1;
`;

const StyledCustomSelect = styled.div<IStyledCustomSelect>`
  position: relative;
  width: 100%;
  font-weight: 500;
  z-index: 1;
  padding: 12px;
  height: 50px;
  line-height: 25px;
  font-size: 14px;

  border: 1px solid
    ${(props) => (props.isOpDetails ? "none" : baseTheme.colors.gray)};
  border-radius: ${(props) => (props.isOpDetails ? 0 : "49px")};
  background-color: ${(props) =>
    props.isOpDetails ? "transparent" : baseTheme.colors.gray};
  @media (min-width: 1921px) {
    font-size: 18px;
    height: 100%;
  }
`;

const StyledCustomOptions = styled.div`
  position: absolute;
  top: 95%;
  left: 0;
  width: 100%;
  font-size: 14px;
  background-color: ${baseTheme.colors.gray};
  border: 1px solid ${baseTheme.colors.main};
  border-radius: ${baseTheme.sizes.radius.input}px;
  z-index: 1;
  overflow-y: auto;
  max-height: 125px;
  @media (min-width: 1921px) {
    font-size: 18px;
  }
  div {
    padding: 0.5rem;
    width: 100%;
    cursor: pointer;
    transition: background-color 0.3s;
    &:hover {
      background-color: ${baseTheme.colors.main};
      color: ${baseTheme.colors.gray};
    }
  }
`;

const Select: React.FC<SelectProps> = ({
  label,
  options,
  onChange,
  placeholder = "Select ...",
  isOpDetails,
  isEditField = false,
  color = "#b4b4b4",
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<Option | null>(null);
  useEffect(() => {
    const matchingOption = options.find(
      (option) => option.label === placeholder
    );
    if (matchingOption) {
      setSelectedOption(matchingOption);
      onChange(matchingOption.value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <StyledInputWrap isEditField={isEditField}>
      {label && <StyledLabel>{label}</StyledLabel>}
      <StyledSelectWrapper>
        <StyledCustomSelect
          isOpDetails={isOpDetails}
          onClick={() => setIsOpen(!isOpen)}
        >
          {selectedOption ? (
            selectedOption.label.length > 30 ? (
              `${selectedOption.label.slice(0, 25)}...`
            ) : (
              selectedOption.label
            )
          ) : (
            <span style={{ color: color }}>
              {placeholder.length > 30
                ? `${placeholder.slice(0, 25)}...`
                : placeholder}
            </span>
          )}
        </StyledCustomSelect>

        {isOpen && (
          <StyledCustomOptions>
            {options.map((option) => (
              <div
                key={option.value}
                onClick={() => {
                  onChange(option.value);
                  setSelectedOption(option);
                  setIsOpen(false);
                }}
              >
                {option.label}
              </div>
            ))}
          </StyledCustomOptions>
        )}
      </StyledSelectWrapper>
    </StyledInputWrap>
  );
};

export default Select;
