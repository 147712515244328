import { create } from "zustand";

export interface FAQ {
    question: string;
    answer: string;
    questionId: string; 
    answerId: string; 
  }

export type LandingContent = {
  introTitle: string;
  introFirstSubtext: string;
  introTrialText: string;
  introSecondSubtext: string;
  historyTitle: string;
  historyText: string;
  missionTitle: string;
  missionText: string;
  faq: FAQ[];
  price: string;
};

type LandingContentStore = {
  initialState: LandingContent;
  landingContent: LandingContent;
  setLandingContent: (content: LandingContent) => void;
  setInitialState: (content: LandingContent) => void;
  updateField: (field: keyof LandingContent, value: string) => void;
  updateFaq: (index: number, field: keyof FAQ, value: string) => void;
  getChangedFields: () => Partial<LandingContent>;
  getChangedFaqs: () => { index: number; faq: FAQ }[];
  resetLandingContent: () => void;
  resetInitialState: () => void;
};

const defaultState: LandingContent = {
  introTitle: "",
  introFirstSubtext: "",
  introTrialText: "",
  introSecondSubtext: "",
  historyTitle: "",
  historyText: "",
  missionTitle: "",
  missionText: "",
  faq: [{ question: "", answer: "", questionId: "", answerId: "" }],
  price: "",
};

export const useLandingContentStore = create<LandingContentStore>((set, get) => ({
  initialState: JSON.parse(JSON.stringify(defaultState)),
  landingContent: JSON.parse(JSON.stringify(defaultState)),
  setLandingContent: (content) => set({ landingContent: JSON.parse(JSON.stringify(content)) }),
  setInitialState: (content) => set({ initialState: JSON.parse(JSON.stringify(content)) }),
  updateField: (field, value) =>
    set((state) => ({
      landingContent: { ...state.landingContent, [field]: value },
    })),
  updateFaq: (index, field, value) =>
    set((state) => {
      const updatedFaq = state.landingContent.faq.map((item, idx) =>
        idx === index ? { ...item, [field]: value } : item
      );
      return { landingContent: { ...state.landingContent, faq: updatedFaq } };
    }),
  getChangedFaqs: () => {
    const { initialState, landingContent } = get();
    const initialFaq = initialState.faq;
    const currentFaq = landingContent.faq;

    const changedFaqs = currentFaq.reduce<{ index: number; faq: FAQ }[]>(
      (acc, faq, index) => {
        if (JSON.stringify(faq) !== JSON.stringify(initialFaq[index])) {
          acc.push({ index, faq });
        }
        return acc;
      },
      []
    );

    return changedFaqs;
  },
  getChangedFields: () => {
    const { initialState, landingContent } = get();
    const changedFields: Partial<LandingContent> = {};

    Object.keys(initialState).forEach((key) => {
      const fieldKey = key as keyof LandingContent;
      const initialValue = initialState[fieldKey];
      const currentValue = landingContent[fieldKey];

      if (Array.isArray(initialValue) && Array.isArray(currentValue)) {
        if (JSON.stringify(initialValue) !== JSON.stringify(currentValue)) {
          changedFields[fieldKey] = currentValue as any;
        }
      } else if (initialValue !== currentValue) {
        changedFields[fieldKey] = currentValue as any;
      }
    });

    return changedFields;
  },
  resetLandingContent: () => set({ landingContent: JSON.parse(JSON.stringify(defaultState)) }),
  resetInitialState: () => set({ initialState: JSON.parse(JSON.stringify(defaultState)) }),
}));
