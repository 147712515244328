import { styled } from "styled-components";
import { baseTheme } from "styles/theme";


export const ContentManagerWrapper = styled.div<{ isFull: boolean }>`
  position: relative;
  background-color: rgb(255, 255, 255);
  padding: 40px 20px;
  width: 100%;  
  height: ${({ isFull }) => (isFull ? "100vh" : "auto")};
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 768px) {
    padding: 15px 10px;
    height: ${({ isFull }) => (isFull ? "auto" : "auto")};
  }
`;

export const Section = styled.section`
  width: 100%;
  max-width: 800px;
  padding: 20px;
  margin-bottom: 30px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 1px;
  @media (max-width: 768px) {
    padding: 5px;
  }
`;

export const Row = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const InputWrapper = styled.div`
  width: 49%;
`;

export const TitleSection = styled.h2`
  color: ${baseTheme.colors.black};
  text-align: center;
  font-size: 16px;
  line-height: 1.7;
  font-weight: 600;
  padding-bottom: 10px;
  @media (min-width: 1921px) {
    font-size: 18px;
    line-height: 28px;
  }
`;

export const TextArea = styled.textarea`
  width: 100%;
  outline: none;
  background-color: transparent;
  font-family: "Poppins", sans-serif;
  border-color: transparent;
  line-height: 1.4;
  font-size: 14px;
  border: none;
  &::placeholder {
    color: ${({ color }) => (color ? color : baseTheme.colors.textGray)};
  }
  @media (min-width: 1921px) {
    font-size: 19px;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  margin: auto;
`;

export const FAQItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  padding: 20px 0 10px;
  background-color: #ffffff;
  border-top: 1px solid ${baseTheme.colors.gray};
`;

export const StyledLabel = styled.label`
  font-size: 14px;
  padding-left: 15px;
  /* padding-bottom: 5px; */
  font-weight: 500;
`;

export const Mb10 = styled.div`
  margin-bottom: 10px;
`;

export const AccordionWrapper = styled.div`
  width: 100%;
  max-width: 800px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  margin-bottom: 16px;
  overflow: hidden;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
`;

export const AccordionHeader = styled.div`
  background-color: #f9f9f9;
  padding: 16px;
  font-size: 16px;
  font-weight: 600;
  color: #333;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: #f1f1f1;
  }

  .icon {
    font-size: 18px;
    color: #333;
    transition: transform 0.3s ease;

    &.open {
      transform: rotate(180deg); 
    }
  }
`;

export const AccordionContent = styled.div<{ isOpen: boolean }>`
  max-height: ${({ isOpen }) => (isOpen ? "500px" : "0")};
  overflow: hidden;
  padding: ${({ isOpen }) => (isOpen ? "16px" : "0 16px")};
  background-color: #fff;
  border-top: ${({ isOpen }) => (isOpen ? "1px solid #e0e0e0" : "none")};
  font-size: 14px;
  color: #555;
  transition: max-height 0.3s ease, padding 0.3s ease;
  @media (max-width: 768px) {
    overflow: auto;
  }
  &.closing {
    max-height: 0;
    padding: 0 16px;
  }
`;

export const TabButtonWrapper = styled.div`
  width: 200px;
  margin-top: 15px;
  @media (max-width: 768px) {
    width: 150px;
  }
`;

export const SectionWrapper = styled.div`
  margin-top: 20px;
  width: 700px;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const TabsContainer = styled.div`
  display: flex;
  border-bottom: 2px solid #ddd;
  width: 700px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const Tab = styled.div<{ isActive: boolean }>`
  padding: 10px 20px;
  margin-bottom: -3px;
  cursor: pointer;
  border-bottom: ${({ isActive }) => (isActive ? "2px solid #199A8E" : "none")};
  font-weight: ${({ isActive }) => (isActive ? "bold" : "normal")};
  color: ${({ isActive }) => (isActive ? "#199A8E" : "#333")};
  transition: color 0.3s ease;

  &:hover {
    color: #199A8E;
  }
`;

export const DeleteIcon = styled.div`
align-self: flex-end;
cursor: pointer;
`