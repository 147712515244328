import { useState, useEffect, useMemo } from "react";
import { getToken } from "helpers";
import {
  ContentManagerWrapper,
  SectionWrapper,
  Tab,
  TabButtonWrapper,
  TabsContainer,
} from "./styles";

import {
  FAQ,
  LandingContent,
  useLandingContentStore,
} from "store/useLandingContentStore";
import { useAxios } from "hooks/useAxios";

import Intro from "./Intro";
import History from "./History";
import Mission from "./Mission";
import FAQSection from "./FAQ";
import Price from "./Price";
import Button from "components/Buttons/Button";
import Accordion from "./Accordion";
import { TitleTab } from "components/DataComponent";
import Toast from "components/Toast";

function ContentManager() {
  const { request, error } = useAxios();
  const token = getToken();
  const {
    setLandingContent,
    getChangedFaqs,
    setInitialState,
    getChangedFields,
  } = useLandingContentStore();

  const [isUpdateContent, setIsUpdateContent] = useState(false);
  const [activeSection, setActiveSection] = useState<string | null>("intro");
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= 768);
  const [heightContainer, setHeightContainer] = useState(true);
  useEffect(() => {
    if (activeSection === "faq") {
      setHeightContainer(false);
    } else {
      setHeightContainer(true);
    }
  }, [activeSection]);

  const changedFields = getChangedFields();
  const loginDisabled = useMemo(() => {
    return Object.values(changedFields).some(
      (value) =>
        value === "" ||
        (Array.isArray(value) && value.some((item: any) => item === ""))
    );
  }, [changedFields]);

  useEffect(() => {
    if (isMobile) {
      setActiveSection(null);
    } else {
      setActiveSection("intro");
    }
  }, [isMobile]);

  const mapContentToLandingContent = (data: any[]): LandingContent => {
    const faq = data
      .filter((item) => item.name.startsWith("faq_"))
      .reduce<FAQ[]>((acc, item) => {
        const match = item.name.match(/faq_\[(\d+)]_(title|text)/);
        if (match) {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const [_, index, field] = match;
          const faqIndex = parseInt(index, 10);

          if (!acc[faqIndex]) {
            acc[faqIndex] = {
              question: "",
              answer: "",
              questionId: "",
              answerId: "",
            };
          }

          if (field === "title") {
            acc[faqIndex].question = item.value;
            acc[faqIndex].questionId = item.id;
          }

          if (field === "text") {
            acc[faqIndex].answer = item.value;
            acc[faqIndex].answerId = item.id;
          }
        }
        return acc;
      }, []);

    return {
      introTitle: data.find((item) => item.name === "intro_title")?.value || "",
      introFirstSubtext:
        data.find((item) => item.name === "intro_first_subtext")?.value || "",
      introTrialText:
        data.find((item) => item.name === "intro_trial_text")?.value || "",
      introSecondSubtext:
        data.find((item) => item.name === "intro_second_subtext")?.value || "",
      historyTitle:
        data.find((item) => item.name === "history_title")?.value || "",
      historyText:
        data.find((item) => item.name === "history_text")?.value || "",
      missionTitle:
        data.find((item) => item.name === "mission_title")?.value || "",
      missionText:
        data.find((item) => item.name === "mission_text")?.value || "",
      price: data.find((item) => item.name === "price")?.value || "",
      faq,
    };
  };

  const getContent = async () => {
    const res = await request("web-content", "GET", null, {
      Authorization: `Bearer ${token}`,
    });

    const data = res?.data || [];

    const mappedLandingContent = mapContentToLandingContent(data);
    setInitialState(mappedLandingContent);
    setLandingContent(mappedLandingContent);
  };

  useEffect(() => {
    getContent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const camelToSnake = (str: string) => {
    return str.replace(/[A-Z]/g, (match) => `_${match.toLowerCase()}`);
  };

  const handleSave = async () => {
    const changedFields = getChangedFields();
    const changedFaqs = getChangedFaqs();
    const requests = [];

    if (changedFaqs.length > 0) {
      changedFaqs.forEach(({ index, faq }) => {
        setIsUpdateContent(true);
        requests.push(
          request(
            `admin/web-content`,
            "POST",
            { name: `faq_[${index}]_title`, value: faq.question },
            { Authorization: `Bearer ${token}` }
          )
        );

        requests.push(
          request(
            `admin/web-content`,
            "POST",
            { name: `faq_[${index}]_text`, value: faq.answer },
            { Authorization: `Bearer ${token}` }
          )
        );

        setIsUpdateContent(false);
      });
    }

    for (const [key, value] of Object.entries(changedFields)) {
      if (key !== "faq") {
        const snakeCaseKey = camelToSnake(key);
        setIsUpdateContent(true);

        requests.push(
          request(
            `admin/web-content`,
            "POST",
            {
              name: snakeCaseKey,
              value: value || "",
            },
            {
              Authorization: `Bearer ${token}`,
            }
          )
        );
        setIsUpdateContent(false);
      }
    }

    try {
      setIsUpdateContent(true);
      await Promise.all(requests);
      await getContent();
      setIsUpdateContent(false);
    } catch (error) {
      console.error("Error:", error);
    }
    setIsUpdateContent(false);
  };

  const sections = [
    { id: "intro", title: "Intro", Component: Intro },
    { id: "history", title: "History", Component: History },
    { id: "mission", title: "Mission", Component: Mission },
    { id: "price", title: "Price", Component: Price },
    { id: "faq", title: "FAQ", Component: FAQSection },
  ];

  return (
    <>
      <ContentManagerWrapper isFull={heightContainer}>
        <div style={{ paddingBottom: "40px" }}>
          <TitleTab>Content Management</TitleTab>
        </div>

        {!isMobile && (
          <SectionWrapper
            style={{
              display: "flex",
              borderBottom: "2px solid #ddd",
              width: "700px",
            }}
          >
            {sections.map(({ id, title }) => (
              <TabsContainer>
                <Tab
                  key={id}
                  isActive={activeSection === id}
                  onClick={() => setActiveSection(id)}
                >
                  {title}
                </Tab>
              </TabsContainer>
            ))}
          </SectionWrapper>
        )}

        <SectionWrapper>
          {!isMobile
            ? sections.map(({ id, Component }) =>
                activeSection === id ? <Component key={id} /> : null
              )
            : sections.map(({ id, title, Component }) => (
                <Accordion
                  key={id}
                  title={title}
                  isOpen={activeSection === id}
                  onToggle={() =>
                    setActiveSection(activeSection === id ? null : id)
                  }
                >
                  <Component />
                </Accordion>
              ))}
        </SectionWrapper>

        {activeSection && (
          <TabButtonWrapper>
            <Button
              disabled={loginDisabled}
              text="Save"
              loading={isUpdateContent}
              onClick={handleSave}
            />
          </TabButtonWrapper>
        )}
      </ContentManagerWrapper>
      {error && <Toast error={error} />}
    </>
  );
}

export default ContentManager;
