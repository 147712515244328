import React, { useEffect, useReducer, useRef, useState } from "react";
import styled from "styled-components";
import Download from "assets/icons/Download";
import Toast from "components/Toast";
import { baseTheme } from "styles/theme";
import { useAxios } from "hooks/useAxios";

import {
  IUserLStorage,
  getFromLocalStorageUser,
  getStatusMember,
  getToken,
} from "helpers";
import InformModal from "./Modal/ConfirmModal";
import moment from "moment";

const DropdownContainer = styled.div`
  position: relative;
  height: 45px;
  width: 100%;
`;

const DropdownButton = styled.div`
  display: flex;
  border-radius: 45px;
  height: 45px;
  font-weight: 600;
  padding: 0 10px;
  font-size: 14px;
  align-items: center;
  justify-content: center;
  color: ${baseTheme.colors.white};
  background-color: ${baseTheme.colors.main};
  line-height: 1.4;
  cursor: pointer;
  @media (min-width: 1921px) {
    font-size: 18px;
  }
`;

const DropdownContent = styled.div<{ open: boolean }>`
  position: absolute;
  font-size: 0.85rem;
  margin-top: 2px;
  border-radius: ${baseTheme.sizes.radius.button}px;
  background-color: #f9f9f9;
  width: 100%;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 2;
  display: ${({ open }) => (open ? "block" : "none")};
`;

const DropdownOption = styled.a`
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  cursor: pointer;

  &:hover {
    background-color: ${baseTheme.colors.textGray};
  }
  @media (min-width: 1921px) {
    font-size: 18px;
  }
`;

interface IDropdownFormats {
  activeTab: string;
  word: string;
  startDate: Date | null;
  endDate: Date | null;
}

function DropdownFormats({
  activeTab,
  word,
  startDate,
  endDate,
}: IDropdownFormats) {
  const [showModal, toggle] = useReducer((showModal) => !showModal, false);
  const statusMember = getStatusMember();
  const [tabData, setTabData] = useState("");
  const { request, error } = useAxios();
  const token = getToken();
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const user: IUserLStorage = getFromLocalStorageUser("user");
  const currentDateAndTime = moment().format("YYYY-MM-DD_HH-mm-ss");
  const { first_name, last_name } = user || {};

  const options = [
    { value: "CSV", key: "csv" },
    { value: "XLS", key: "xls" },
  ];

  useEffect(() => {
    switch (activeTab) {
      case "Pathologies":
        setTabData("operation-case/pathology");
        break;
      case "Complications":
        setTabData("operation-case/complication");
        break;
      case "Case logs":
        setTabData("operation-case");
        break;
    }
  }, [activeTab]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false);
    }
  };

  const toggleDropdown = () => {
    if (statusMember === "demo") {
      toggle();
      return;
    }
    setIsOpen(!isOpen);
  };

  const selectOption = async (option: string) => {
    if (option === "csv" && first_name && last_name) {
      downloadCSV();
    } else if (option === "xls" && first_name && last_name) {
      downloadXLS(word, startDate, endDate);
    }
  };
  const downloadXLS = async (
    word: string,
    startDate: Date | null,
    endDate: Date | null
  ) => {
    let url = `${process.env.REACT_APP_BASE_API}${tabData}/xls`;

    const params = new URLSearchParams();
    if (word) params.append("search", word);
    if (startDate) params.append("date_from", startDate.toISOString());
    if (endDate) params.append("date_to", endDate.toISOString());

    // Check if any params were added
    if (params.toString()) {
      url += `?${params.toString()}`;
    }

    fetch(url, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Access-Control-Expose-Headers": "Content-Disposition",
      },
    })
      .then((response) => {
        return response.blob();
      })
      .then((blob) => {
        const blobUrl = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = blobUrl;

        const nameFile = `User_${first_name}_${last_name}_Operation_Cases_${currentDateAndTime}.xls`;
        link.setAttribute("download", nameFile);
        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(blobUrl);
        document.body.removeChild(link);
      })
      .catch((error) => console.error("error xls:", error));
  };

  const downloadCSV = async () => {
    const res = await request(
      `${tabData}/csv?search=${word}`,
      "GET",
      null,
      {
        Authorization: `Bearer ${token}`,
      },
      { search: word, date_from: startDate, date_to: endDate }
    );

    if (res) {
      const csvData = new Blob([res], { type: "text/csv;charset=utf-8;" });
      const csvURL = window.URL.createObjectURL(csvData);
      const tempLink = document.createElement("a");
      tempLink.href = csvURL;
      const nameFile = `User_${first_name}_${last_name}_Operation_Cases_${currentDateAndTime}.csv`;
      tempLink.setAttribute("download", nameFile);
      document.body.appendChild(tempLink);
      tempLink.click();
      document.body.removeChild(tempLink);
    } else {
      console.error("Ошибка при получении CSV-данных");
    }
  };

  return (
    <>
      <DropdownContainer ref={dropdownRef}>
        <DropdownButton onClick={toggleDropdown}>
          <Download color={baseTheme.colors.white} />
          Download
        </DropdownButton>
        <DropdownContent open={isOpen}>
          {options.map((option) => (
            <DropdownOption
              key={option.key}
              onClick={() => selectOption(option.key)}
            >
              {option.value}
            </DropdownOption>
          ))}
        </DropdownContent>
      </DropdownContainer>

      <InformModal
        isOpen={showModal}
        title={
          "Reports download is available only for subscribed users. Please upgrade your subscription"
        }
        confirmButton={toggle}
      />
      {error && <Toast error={error} />}
    </>
  );
}

export default DropdownFormats;
