import React from "react";
import { LandingContent } from "store/useLandingContentStore";
import styled from "styled-components";
import { baseTheme } from "styles/theme";

const AboutWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 100px 0 20px;
`;
export const LandingTitleSection = styled.h3`
  color: ${baseTheme.colors.main};
  font-size: 18px;
  letter-spacing: 9px;
  line-height: 40px;
  font-weight: 600;
  padding-left: 20px;
  text-align: center;
  text-transform: uppercase;
  width: 100%;
  @media (max-width: 991px) {
    font-size: 16px;
    letter-spacing: 6px;
  }
  @media (min-width: 1921px) {
    font-size: 25px;
  }
`;
const AboutColumn = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const Card = styled.div`
  max-width: 450px;
  min-width: 300px;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  padding: 20px;
  filter: drop-shadow(0 15px 34px rgba(30, 47, 87, 0.1));
  transition: box-shadow 0.25s;
`;
const Image = styled.img`
  width: 100%;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
`;
const TextWrapper = styled.div`
  background-color: ${baseTheme.colors.white};
  padding: 20px;
  flex-direction: column;
  flex: 1 1 auto;
  display: flex;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  filter: drop-shadow(0 5px 6.5px rgba(30, 47, 87, 0.09));
`;
const Title = styled.h3`
  font-size: 18px;
  letter-spacing: 9px;
  line-height: 61px;
  color: ${baseTheme.colors.main};
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
  width: 100%;
  @media (min-width: 1921px) {
    font-size: 25px;
  }
`;
const Text = styled.p`
  letter-spacing: 0;
  color: #484848;
  font-weight: 400;
  text-align: center;
  font-size: 16px;
  line-height: 26px;
  @media (min-width: 1921px) {
    font-size: 18px;
    line-height: 31px;
  }
  @media (min-width: 2500px) {
    font-size: 24px;
    line-height: 40px;
  }
`;

interface IAbout {
  content?: LandingContent;
}

function About({ content }: IAbout) {
  // function parseTextWithLimit(text?: string, limit: number = 3): React.ReactNode {
  //   if (!text) return null;
  //   const sentences = text.split(".");

  //   const limitedSentences = sentences.slice(0, limit);
  //   const remainingText = sentences.slice(limit).join(".").trim();

  //   if (remainingText) {
  //     limitedSentences[limitedSentences.length - 1] += ` ${remainingText}`;
  //   }

  //   return limitedSentences
  //     .filter((sentence) => sentence.trim().length > 0)
  //     .map((sentence, index) => (
  //       <span key={index} style={{ display: "block", marginBottom: "10px" }}>
  //         {sentence.trim()}
  //       </span>
  //     ));
  // }

  return (
    <AboutWrapper id="about">
      <LandingTitleSection>ABOUT US</LandingTitleSection>
      <AboutColumn>
        <Card>
          <Image src="https://img1.wsimg.com/isteam/stock/a28D7m/:/cr=t:12.4%25,l:0%25,w:100%25,h:75.21%25/rs=w:600,h:300,cg:true" />
          <TextWrapper>
            <Title>{content?.historyTitle || "Our history"}</Title>
            <Text>
              {content?.historyText ||
                "We believe that good clinical data should be captured in real time in the course of delivery of patient care. Not retrospectively by tediously reviewing clinical files and manually entering data into databases. The vision for Snap Log is that barriers to data entry must be eliminated by prioritising a smooth user experience. As a result, the Snap Log surgical logbook does not require any manual data entry. All fields can be populated using optical character recognition (OCR) technology."}
            </Text>
          </TextWrapper>
        </Card>

        <Card>
          <Image src="https://img1.wsimg.com/isteam/stock/1YNpAdQ/:/cr=t:12.5%25,l:0%25,w:100%25,h:75%25/rs=w:600,h:300,cg:true" />
          <TextWrapper>
            <Title>{content?.missionTitle || "Our mission"}</Title>
            <Text>
              {
                content?.missionText ||
                  "We believe that good clinical data should be captured in real time in the course of delivery of patient care. Not retrospectively by tediously reviewing clinical files and manually entering data into databases. The vision for Snap Log is that barriers to data entry must be eliminated by prioritising a smooth user experience. As a result, the Snap Log surgical logbook does not require any manual data entry. All fields can be populated using optical character recognition (OCR) technology."
              }
            </Text>
          </TextWrapper>
        </Card>
      </AboutColumn>
    </AboutWrapper>
  );
}

export default About;
