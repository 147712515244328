import {
  IUserLStorage,
  getFromLocalStorageUser,
} from "helpers";
import styled from "styled-components";
import { baseTheme } from "styles/theme";



const ProfileUserInnerContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

// const DeleteAccount = styled.div`
//   display: flex;
//   flex-direction: row;
//   align-items: center;
//   justify-content: flex-start;
//   border-radius: 20px;

//   width: 100%;
//   padding: 15px 10px;

//   margin: 0 auto;
//   box-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.08);

//   @media (max-width: 1024px) {
//     margin: 20px auto;
//   }
// `;

const ProfileUseRouterContainer = styled.div`
  height: 100%;

  display: flex;
  flex-direction: column;

  @media (max-width: 900px) {
    border-right: none;
    padding-bottom: 50px;
    padding-top: 50px;
  }
`;

const Avatar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(242, 242, 242);
  width: 130px;
  height: 130px;
  border-radius: 150px;
`;

const Letters = styled.p`
  font-size: 50px;
  color: ${baseTheme.colors.main};
  font-family: "Poppins", sans-serif;
`;
const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 20px;
`;

export const Text = styled.p`
  font-family: "Poppins", sans-serif;
  color: ${baseTheme.colors.black};
  text-align: left;
  font-size: 16px;
  font-weight: 500;
  padding-bottom: 20px;
  @media (min-width: 1921px) {
    font-size: 18px;
  }
`;
const TextSubscribe = styled.p`
  font-family: "Poppins", sans-serif;
  color: ${baseTheme.colors.black};
  text-align: left;
  font-size: 15px;
  padding-bottom: 20px;
  flex: 1;
  font-weight: 500;
`;
export const IconBubble = styled.div`
  background-color: rgb(242, 242, 242);
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
  display: flex;
  border-radius: 50px;
  margin-right: 10px;
`;

export const ButtonSubscribe = styled.button<{
  textDecoration?: string;
  color?: string;
}>`
  border-radius: 8px;
  padding: 0;
  cursor: pointer;
  font-family: "Poppins", sans-serif;
  justify-content: center;
  color: ${({ color }) => color || "#007AFF"};
  font-weight: 500;
  font-size: 16px;
  border: none;
  text-decoration: ${({ textDecoration }) => textDecoration || "underline"};
  background-color: transparent;

  @media (min-width: 1921px) {
    font-size: 18px;
  }

  @media (max-width: 900px) {
    align-items: center;
  }
`;

interface IProfileUserinfo {
  settingsProfile?: () => void;
}

function ProfileUserinfo({ settingsProfile }: IProfileUserinfo) {


  const user: IUserLStorage = getFromLocalStorageUser("user");
  const { first_name, role, last_name, subscribe_status, type } = user;

  function translateData(data: string) {
    switch (data) {
      case "set-trainee":
        return "SET trainee";
      case "consultant":
        return "consultant";
      case "unaccredited-trainee":
        return "unaccredited trainee";
    }
  }

  return (
    <>
      {first_name && last_name && (
        <>
          <ProfileUseRouterContainer>
            <ProfileUserInnerContainer>
              <Avatar>
                <Letters>{first_name.slice(0, 1) || null}</Letters>
                <Letters>{last_name.slice(0, 1) || null}</Letters>
              </Avatar>
              <TextContainer>
                {role !== "superadmin" && (
                  <>
                    <TextSubscribe>
                      User Type: {translateData(type)}.
                    </TextSubscribe>
                    <TextSubscribe>
                      Subscription: {subscribe_status}.
                    </TextSubscribe>
                  </>
                )}
              </TextContainer>
            </ProfileUserInnerContainer>
          </ProfileUseRouterContainer>
        </>
      )}
    </>
  );
}

export default ProfileUserinfo;
