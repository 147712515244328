import React, { useEffect, useState } from "react";

import UserType from "components/Auth/UserType";

import { useAxios } from "hooks/useAxios";
import { useNavigate } from "react-router-dom";

import { baseTheme } from "styles/theme";
import { styled } from "styled-components";
import { getToken, getUserRole, saveUserType } from "helpers";
import userTypeButtons from "data/userTypeButtons";
import Button from "components/Buttons/Button";
import Hospital from "./components/Hospital";
import SetLevel from "./components/SetLevel";
import Supervisor from "./components/Supervisor";

import { MdArrowBackIosNew } from "react-icons/md";
import { IHospital } from "interfaces";
import Rotation from "./components/Rotation";
import SupervisionLevel from "./components/SupervisionLevel";
import PGYLevel from "./components/PGYLevel";

function UserSettingsPage() {
  const token = getToken();
  const navigate = useNavigate();
  const { request } = useAxios();

  // Form state
  const [currentStep, setCurrentStep] = useState(1);
  const [userType, setUserType] = useState("");
  const [rotation, setRotation] = useState("");
  const [setLevel, setSetLevel] = useState("");
  const [pgyLevel, setPgyLevel] = useState("");
  const [supervisorName, setSupervisorName] = useState("");
  const [supervisionLevel, setSupervisionLevel] = useState("");
  const [hospitalItem, setHospitalItem] = useState<IHospital>();
  const [isFormValid, setIsFormValid] = useState(false);

  useEffect(() => {
    let isValid = false;

    if (userType === "consultant") {
      isValid = !!hospitalItem;
    } else if (userType === "set-trainee") {
      isValid =
        !!hospitalItem &&
        !!rotation &&
        !!setLevel &&
        !!supervisorName &&
        !!supervisionLevel;
    } else if (userType === "unaccredited-trainee") {
      isValid =
        !!hospitalItem &&
        !!rotation &&
        !!pgyLevel &&
        !!supervisorName &&
        !!supervisionLevel;
    }

    setIsFormValid(isValid);
  }, [
    hospitalItem,
    supervisionLevel,
    rotation,
    setLevel,
    pgyLevel,
    supervisorName,
    userType,
  ]);

  const statusRole = getUserRole();

  const SaveUserType = (value: string) => {
    setUserType(value);
    setCurrentStep(2);
  };

  const goBack = () => {
    setCurrentStep((prev) => Math.max(prev - 1, 1));
  };
  const SaveUserSettings = async () => {
    const hospital = hospitalItem?.id;
    const supervision_level = supervisionLevel;
    let pgy;
    let set;
    let supervisor;

    await request(
      `profile/hospital`,
      "POST",
      {
        hospital_list_id: hospital,
      },
      {
        Authorization: `Bearer ${token}`,
      }
    );

    if (userType === "set-trainee" || userType === "unaccredited-trainee") {
      const res = await request(
        "profile/supervisor",
        "POST",
        { name: supervisorName },
        {
          Authorization: `Bearer ${token}`,
        }
      );
      supervisor = res.data.id;
    }
    if (pgyLevel) {
      pgy = pgyLevel;
    }

    if (setLevel) {
      set = setLevel;
    }

    const presets = {
      hospital,
      ...(userType === "set-trainee" && {
        rotation,
        set,
        supervisor,
        supervision_level,
      }),
      ...(userType === "unaccredited-trainee" && {
        rotation,
        supervisor,
        pgy,
        supervision_level,
      }),
    };

    const res = await request(
      "profile/type-speciality",
      "POST",
      {
        type: userType,
        speciality: "lol",
      },
      {
        Authorization: `Bearer ${token}`,
      }
    );

    if (res.message === "Updated") {
     await request(
        "profile/presets",
        "POST",
        {
          presets: presets,
        },
        {
          Authorization: `Bearer ${token}`,
        }
      );
    }

    saveUserType(userType);
    if (statusRole === "admin" || statusRole === "superadmin") {
      navigate("/users");
    } else {
      navigate("/case-logs");
    }
  };

  const getFieldsForUserType = () => {
    switch (userType) {
      case "consultant":
        return (
          <RelativeWrapper>
            <div style={{ zIndex: 12, position: "relative" }}>
              <Title>Hospital</Title>
              <Hospital setHospital={setHospitalItem} />
            </div>
          </RelativeWrapper>
        );
      case "set-trainee":
        return (
          <RelativeWrapper>
            <Title>SET</Title>
            <div style={{ zIndex: 15, position: "relative" }}>
              <SetLevel defaultValue="Select Set" setSetLevel={setSetLevel} />
            </div>

            <div style={{ zIndex: 12, position: "relative" }}>
              <Title>Hospital</Title>
              <Hospital setHospital={setHospitalItem} />
            </div>

            <Title>Supervisor</Title>
            <Supervisor
              supervisor={supervisorName}
              setSupervisor={setSupervisorName}
            />
            <div style={{ zIndex: 10, position: "relative" }}>
              <Title>Supervision Level</Title>
              <SupervisionLevel
                defaultValue="Select Supervision Level"
                setSupervisionLevel={setSupervisionLevel}
              />
            </div>
            <Title>Rotation</Title>
            <Rotation defaultValue="Rotation" setRotation={setRotation} />
          </RelativeWrapper>
        );
      case "unaccredited-trainee":
        return (
          <RelativeWrapper>
            <Title>PGY</Title>
            <PGYLevel pgyLevel={pgyLevel} setPgyLevel={setPgyLevel} />

            <div style={{ zIndex: 12, position: "relative" }}>
              <Title>Hospital</Title>
              <Hospital setHospital={setHospitalItem} />
            </div>

            <Title>Supervisor</Title>
            <Supervisor
              supervisor={supervisorName}
              setSupervisor={setSupervisorName}
            />
            <div style={{ zIndex: 10, position: "relative" }}>
              <Title>Supervision Level</Title>
              <SupervisionLevel
                defaultValue="Select Supervision Level"
                setSupervisionLevel={setSupervisionLevel}
              />
            </div>

            <Title>Rotation</Title>
            <Rotation defaultValue="Rotation" setRotation={setRotation} />
          </RelativeWrapper>
        );
      default:
        return null;
    }
  };

  return (
    <>
      {currentStep > 1 && (
        <ButtonWrapper>
          <MdArrowBackIosNew color={baseTheme.colors.black} fontSize={18} />
          <Button
            fontWeight={500}
            fontSize="15px"
            flat={true}
            text="Back"
            as="a"
            color={baseTheme.colors.black}
            onClick={goBack}
          />
        </ButtonWrapper>
      )}
      <UserSettingsContainer>
        {currentStep === 1 && (
          <CenterContainer>
            <UserType
              buttons={userTypeButtons}
              title={"I am a:"}
              onPress={SaveUserType}
            />
          </CenterContainer>
        )}
        {currentStep > 1 && (
          <ContentWrapper>
            <TextWrapper>
              <Text>
                Please complete the fields below to finalize your profile setup.
                Keeping your information updated ensures a seamless and
                customized experience.
              </Text>
              <Spacing />
              <Text>
                You can access the Profile section at any time to make
                adjustments if necessary.
              </Text>
            </TextWrapper>
            <Spacing />
            {getFieldsForUserType()}

            <CenterContainer>
              <Button
                disabled={!isFormValid}
                onClick={SaveUserSettings}
                fontWeight={600}
                color={baseTheme.colors.white}
                fontSize="14px"
                text={"Save"}
              />
            </CenterContainer>
          </ContentWrapper>
        )}
      </UserSettingsContainer>
    </>
  );
}

export default UserSettingsPage;

// Styled Components
const UserSettingsContainer = styled.div`
  max-width: 600px;
  display: flex;
  margin: 0 auto;
  height: 100vh;
  align-items: center;
  @media (max-width: 600px) {
    height: auto;
  }
`;
const ButtonWrapper = styled.div`
  padding-left: 15px;
  padding-top: 5px;
  display: flex;
  align-items: center;
`;
const CenterContainer = styled.div`
  width: 300px;
  margin: 0 auto;
  padding: 10px 0;
`;

const ContentWrapper = styled.div`
  width: 100%;
`;

const TextWrapper = styled.div`
  width: 100%;
  padding: 0 10px;
  @media (max-width: 600px) {
    padding-top: 25px;
  }
`;

const Text = styled.p`
  font-size: 16px;
  line-height: 1.5;
  color: #333;
`;

const Title = styled.h3`
  color: #222222;
  text-align: left;
  padding-left: 5px;
  font-size: 16px;
  line-height: 1.7;
  font-weight: 600;
`;

const Spacing = styled.div`
  height: 16px;
`;

const RelativeWrapper = styled.div`
  width: 300px;
  margin: 0 auto;
`;
