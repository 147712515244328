import React, { useEffect, useReducer, useState } from "react";
import styled from "styled-components";
import AddCustomFields from "./AddCustomFields";
import { baseTheme } from "styles/theme";
import { EditButton } from "../styles";
import Button from "components/Buttons/Button";
import { TitleSection } from "styles/components";
import { SettingsArrayType } from "interfaces";
import CustomField from "./CustomField";
import DescriptionModule from "components/Modal/DescriptionModule";
import { getFromLocalStorageUser, IUserLStorage } from "helpers";

interface CustomFieldData {
  id: string;
  name: string;
  type: string;
  "custom-field-values": [];
}
interface ICustomFieldsList {
  dataSettingsProfile: SettingsArrayType;
  settingsProfile: () => {};
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Text = styled.p`
  font-size: 16px;
  line-height: 24px;
  text-align: center;
`;

const ContainerText = styled.p``;

const Separator = styled.div`
  padding: 8px 0;
`;

const FirstTextContent = () => {
  return (
    <ContainerText>
      <TitleSection>What are Custom Fields?</TitleSection>
      <Text>
        Snap Log empowers users to create meaningful logbook and audit data by
        creating custom data fields.
      </Text>
      <Separator />
      <Text>There are four types of custom fields:</Text>
      <Separator />
      <Text>Binary (Yes/No)</Text>
      <Text>Two-button</Text>
      <Text>Dropdown menu</Text>
      <Text>Free text</Text>
      <Separator />
      <Text>
        Think carefully about the field you want to add. You want your data to
        be meaningful.
      </Text>
    </ContainerText>
  );
};

const CustomFieldsList = ({
  dataSettingsProfile,
  settingsProfile,
}: ICustomFieldsList) => {
  const [showDescModal, toggleDescModal] = useReducer((showDescModal) => !showDescModal, false)
  const [show, toggle] = useReducer((show) => !show, false);
  const [customFields, setCustomFields] = useState<CustomFieldData[]>([]);
  const [data, setData] = useState<CustomFieldData | null>(null);
  const [showCustomModal, setShowCustomModal] = useState(false);
   const user: IUserLStorage = getFromLocalStorageUser("user");
    const { email } = user;
    
  useEffect(() => {
    setCustomFields(dataSettingsProfile[0]?.custom_fields);
  }, [dataSettingsProfile]);


  useEffect(() => {
    const checkIfSeen = () => {
      const hasSeen = localStorage.getItem("hasSeenCustomFields");
      if (!hasSeen || JSON.parse(hasSeen).email !== email) {
        toggleDescModal();
      }
    };

    checkIfSeen();
  }, [email]);

  const handleOk = () => {
    localStorage.setItem("hasSeenCustomFields", JSON.stringify({ email, seen: true }));
    toggleDescModal();
  }

  const customConfig = [
    {
      title: "Add custom fields",
      id: "Add custom fields",
      onPress: () => toggle(),
      color: baseTheme.colors.main,
      bg: baseTheme.colors.transparent,
      showItem: Array.isArray(customFields) && customFields.length < 6,
    },
    ...(Array.isArray(customFields)
      ? customFields.map((field) => ({
          title: field?.name || "N/A",
          id: field?.id,
          color: baseTheme.colors.white,
          bg: baseTheme.colors.main,
          onPress: () => handleFieldPress(field?.id),
          showItem: true,
        }))
      : []),
  ];

  const handleFieldPress = async (fieldId: string) => {
    const customField: any = customFields.filter((item) => item.id === fieldId);
    setData(customField[0]);
    setShowCustomModal(true);
  };

  return (
    <>
      <Container>
        {customConfig.map(
          ({ title, onPress, id, showItem, bg, color }) =>
            showItem && (
              <EditButton key={id}>
                <Button
                  bg={bg}
                  br={color}
                  color={color}
                  onClick={onPress}
                  text={title}
                />
              </EditButton>
            )
        )}
        <AddCustomFields
          getCustomFields={settingsProfile}
          showModal={show}
          dataSettingsProfile={dataSettingsProfile}
          onCloseModal={toggle}
        />
        {customFields && (
          <CustomField
            getCustomFields={settingsProfile}
            data={data}
            setData={setData}
            showModal={showCustomModal}
            onCloseModal={() => setShowCustomModal(false)}
          />
        )}
      </Container>
      <DescriptionModule
        onOk={handleOk}
        showModal={showDescModal}
        firstScreenComponent={<FirstTextContent />}
      />
    </>
  );
};

export default CustomFieldsList;
