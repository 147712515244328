import React, { useState } from "react";
import { baseTheme } from "styles/theme";
import { styled } from "styled-components";
import AccountSettings from "./AccountSettings";
import CoreFields from "./CoreFields";
import Outcomes from "./Outcomes";
import HelpCenter from "./HelpCenter";
import CheckOperationFields from "components/Modal/CheckOperationFields";
import { getFromLocalStorageUser, IUserLStorage } from "helpers";
import CustomFieldsSettings from "./CustomFieldsSettings";

const Container = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  background: ${baseTheme.colors.white};
  @media (max-width: 600px) {
    flex-direction: column;
    height: auto;
  }
`;

const Sidebar = styled.div`
  width: 300px;
  background: ${baseTheme.colors.white};
  display: flex;
  flex-direction: column;
  padding: 20px;
  gap: 12px;
  @media (max-width: 600px) {
    width: 100%;
    padding: 0 10px;
    gap: 0px;
  }
`;
export const SpinnerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
`;
const Content = styled.div`
  width: 100%;
  padding: 20px;
  /* overflow-y: auto; */
  @media (max-width: 600px) {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
`;

const SidebarItem = styled.button`
  background: none;
  border: none;
  text-align: left;
  padding: 12px 16px;
  font-size: 15px;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 12px;
  border-radius: 8px;
  color: ${baseTheme.colors.black};
  transition: background 0.3s, color 0.3s;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  &:hover {
    background: ${baseTheme.colors.gray};
  }

  &.active {
    background: ${baseTheme.colors.primary};
    color: ${baseTheme.colors.white};

    svg {
      background: ${baseTheme.colors.white};
      color: ${baseTheme.colors.primary};
    }
  }
  @media (max-width: 600px) {
    width: 100%;
    padding: 10px;
    gap: 0px;
  }
`;

interface IProfileSettings {
  getUserInfo: () => {};
  settingsProfile: () => {};
  dataSettingsProfile: any;
}
const ProfileSettings = ({
  settingsProfile,
  getUserInfo,
  dataSettingsProfile,
}: IProfileSettings) => {
  const [activeSection, setActiveSection] = useState("account_settings");

  const user: IUserLStorage = getFromLocalStorageUser("user");
  const { role } = user;

  const sections = [
    {
      id: "account_settings",
      title: "Account settings",
      component: <AccountSettings getUserInfo={getUserInfo} settingsProfile={settingsProfile} />,
    },
    {
      id: "core_fields",
      title: "Core Fields",
      component: (
        <CoreFields
          dataSettingsProfile={dataSettingsProfile}
          settingsProfile={settingsProfile}
        />
      ),
    },
    {
      id: "custom_fields",
      title: "Custom Fields",
      component: (
        <CustomFieldsSettings
          dataSettingsProfile={dataSettingsProfile}
          settingsProfile={settingsProfile}
        />
      ),
    },
    {
      id: "outcomes",
      title: "Outcomes",
      component: (
        <Outcomes
          dataSettingsProfile={dataSettingsProfile}
          settingsProfile={settingsProfile}
        />
      ),
    },
    {
      id: "operation-case-fields",
      title: "Operation case fields",
      component: (
        <CheckOperationFields
          dataSettingsProfile={dataSettingsProfile}
          settingsProfile={settingsProfile}
        />
      ),
    },
    {
      id: "help-center",
      title: "Help Center",
      component: <HelpCenter />,
    },
  ];

  const sidebarSections =
    role === "superadmin" || role === "admin"
      ? sections.filter((section) => section.id === "account_settings")
      : sections;

  // if (isLoading) {
  //   return (
  //     <SpinnerWrapper>
  //       <Spinner color={baseTheme.colors.main} />
  //     </SpinnerWrapper>
  //   );
  // }

  return (
    <Container>
      <Sidebar>
        {sidebarSections.map(({ id, title }) => (
          <SidebarItem
            key={id}
            className={activeSection === id ? "active" : ""}
            onClick={() => setActiveSection(id)}
          >
            {title}
          </SidebarItem>
        ))}
      </Sidebar>
      <Content>
        {sections.find((section) => section.id === activeSection)?.component}
      </Content>
    </Container>
  );
};

export default ProfileSettings;
