import React from "react";
import { InfoLogWrap } from "./styles";
import SkeletonComponent from "components/Skeletons/Skeleton";
import { ICaseLog, ISettings } from "interfaces";
import { styled } from "styled-components";
import { baseTheme } from "styles/theme";
import PatientDetails from "./PatientDetails";
import OperationDetails from "./OperationDetails";
import CustomDetails from "./CustomDetails";

interface IInfoLog {
  log: ICaseLog;
  logLoading?: boolean;
  operationCaseFields: ISettings;
  getLog: () => void;
}

export const TitleSection = styled.h4`
  color: ${baseTheme.colors.black};
  text-align: center;
  font-size: 16px;
  line-height: 1.7;
  font-weight: 600;
  @media (min-width: 1921px) {
    font-size: 18px;
    line-height: 28px;
  }
`;

export default function InfoLog({
  operationCaseFields,
  log,
  getLog,
  logLoading,
}: IInfoLog) {
  return (
    <InfoLogWrap>
      {logLoading ? (
        <SkeletonComponent width={300} />
      ) : (
        <>
          <PatientDetails getLog={getLog} log={log} />
          <OperationDetails
            log={log}
            getLog={getLog}
            operationCaseFields={operationCaseFields}
          />
          <CustomDetails log={log} />
        </>
      )}
    </InfoLogWrap>
  );
}
