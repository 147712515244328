import Button from "components/Buttons/Button";
import { styled } from "styled-components";
import { baseTheme } from "styles/theme";
import { TitleSection } from "styles/components";

interface IDeleteCustomField {
  onDelete: () => void;
  name: string;
}

const FieldContainer = styled.div`
  padding-top: 10px;
  width: 100%;
`;

export default function DeleteCustomField({
  onDelete,
  name,
}: IDeleteCustomField) {
  return (
    <FieldContainer>
      <TitleSection>Delete {name} field</TitleSection>
      <Button
        onClick={onDelete}
        fontWeight={600}
        br={baseTheme.colors.error}
        bg="transparent"
        color={baseTheme.colors.error}
        fontSize="14px"
        text="Delete"
      />
    </FieldContainer>
  );
}
