import styled from "styled-components";
import Header from "./Header/Header";
import About from "./About";
import Footer from "./Footer/Footer";
import Hero from "./Hero";
import FaqAccordion from "./FAQComponent";
import Pricing from "./Pricing";
import { getToken } from "helpers";
import { useAxios } from "hooks/useAxios";
import { useEffect, useState } from "react";
import { FAQ, LandingContent } from "store/useLandingContentStore";


const Wrapper = styled.div`
  width: 100%;
  background-color: #f4f4f4;
  position: relative;
  margin: 0 auto;
`;

function LandingPage() {
  const token = getToken();
  const { request,  } = useAxios();

  const [LandingContent, setLandingContent] = useState<LandingContent>();
  const mapContentToLandingContent = (data: any[]): LandingContent => {
    const faq = data
      .filter((item) => item.name.startsWith("faq_"))
      .reduce<FAQ[]>((acc, item) => {
        const match = item.name.match(/faq_\[(\d+)]_(title|text)/);
        if (match) {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const [_, index, field] = match;
          const faqIndex = parseInt(index, 10);

          if (!acc[faqIndex]) {
            acc[faqIndex] = {
              question: "",
              answer: "",
              questionId: "",
              answerId: "",
            };
          }
          if (field === "title") acc[faqIndex].question = item.value;
          if (field === "text") acc[faqIndex].answer = item.value;
        }
        return acc;
      }, []);

    return {
      introTitle: data.find((item) => item.name === "intro_title")?.value || "",
      introFirstSubtext:
        data.find((item) => item.name === "intro_first_subtext")?.value || "",
      introTrialText:
        data.find((item) => item.name === "intro_trial_text")?.value || "",
      introSecondSubtext:
        data.find((item) => item.name === "intro_second_subtext")?.value || "",
      historyTitle:
        data.find((item) => item.name === "history_title")?.value || "",
      historyText:
        data.find((item) => item.name === "history_text")?.value || "",
      missionTitle:
        data.find((item) => item.name === "mission_title")?.value || "",
      missionText:
        data.find((item) => item.name === "mission_text")?.value || "",
      price: data.find((item) => item.name === "price")?.value || "",
      faq,
    };
  };

  const getContent = async () => {
    const res = await request("web-content", "GET", null, {
      Authorization: `Bearer ${token}`,
    });

    const data = res?.data || [];

    const mappedLandingContent = mapContentToLandingContent(data);
    setLandingContent(mappedLandingContent);
  };

  useEffect(() => {
    getContent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // if (loading) {
  //   return (
  //     <div
  //       style={{
  //         height: "100vh",
  //         display: "flex",
  //         alignItems: "center",
  //         justifyContent: "center",
  //       }}
  //     >
  //       <Spinner />
  //     </div>
  //   );
  // }

  return (
    <Wrapper id="home">
      <>
        <Header />
        <Hero content={LandingContent} />
        <About content={LandingContent} />
        <Pricing content={LandingContent} />
        {LandingContent && <FaqAccordion faqs={LandingContent.faq} />}

        <Footer />
      </>
    </Wrapper>
  );
}

export default LandingPage;
