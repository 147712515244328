import CheckOutlined from "@ant-design/icons/CheckOutlined";
import Button from "components/Buttons/Button";
import Input from "components/Inputs/Input";
import InformModal from "components/Modal/ConfirmModal";
import Toast from "components/Toast";
import { getToken } from "helpers";
import { useAxios } from "hooks/useAxios";
import {  useState } from "react";

import styled from "styled-components";
import { TitleSection } from "styles/components";
import { baseTheme } from "styles/theme";

const ButtonWrapper = styled.div`
  padding-bottom: 10px;
  width: 100%;
`;


const Form = styled.form`
  width: 100%;
  padding-top: 10px;
`;

const PgyWrapper = styled.div`
  padding: 0 10px;
`;

export const DefaultSpan = styled.span`
  color: ${baseTheme.colors.black};
  text-align: center;
  font-size: 16px;
  line-height: 1;
  font-weight: 700;
`;
interface IPgyForm {
  settingsProfile: () => void;
  defaultValue: string;
}

export const PgyForm = ({ defaultValue, settingsProfile }: IPgyForm) => {


  const token = getToken();
  const [pgy, setPgy] = useState<string>("");
  const [showModalInternal, setShowModalInternal] = useState(false);
  const { request, loading, error } = useAxios();
  const [errorPgy] = useState<string>("");

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    setPgy(inputValue);
  };

  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    const res = await request(
      "profile/presets",
      "POST",
      {
        presets: {
          pgy: pgy,
        },
      },
      {
        Authorization: `Bearer ${token}`,
      }
    );
    if ((res.message = "Updated")) {
      setShowModalInternal(true);
      setPgy("");

      setTimeout(() => {
        settingsProfile();
        setShowModalInternal(false);
      }, 1000);
    }
  }

  return (
    <>
      <PgyWrapper>
        <TitleSection>
          {defaultValue ? (
            <>
              Your current PGY Level defaults <DefaultSpan>{defaultValue}</DefaultSpan>.
              If you want to change the PGY Level, enter a new value from 1 to 15
            </>
          ) : (
            <>Enter PGY from 1 to 15</>
          )}
        </TitleSection>
        <Form onSubmit={handleSubmit}>
          <Input
            type="text"
            error={errorPgy}
            name="pgy"
            required={true}
            placeholder={"Enter PGY Level"}
            value={pgy}
            onChange={handleInputChange}
          />
          <ButtonWrapper>
            <Button
              fontWeight={600}
              color={baseTheme.colors.white}
              fontSize="14px"
              loading={loading}
              // disabled={buttonDisabled}
              text="Save"
            />
          </ButtonWrapper>
        </Form>
      </PgyWrapper>

      <InformModal
        isOpen={showModalInternal}
        title={"PGY Level changed"}
        icon={
          <CheckOutlined
            style={{ fontSize: "24px", color: baseTheme.colors.main }}
          />
        }
      />
      {error && <Toast error={error} />}
    </>
  );
};
