import React, { useEffect, useState } from "react";
import { useAxios } from "hooks/useAxios";
import MainLayout from "layouts/MainLayout";
import Toast from "components/Toast";
import ProfileSettings from "./components/Settings";
import { baseTheme } from "styles/theme";
import { styled } from "styled-components";
import { getToken, saveStatusMember, saveToLocalStorageUser } from "helpers";

const Wrapper = styled.div`
  display: flex;
  height: 100vh;
  align-items: flex-start;
  justify-content: center;
  flex-direction: row;
  border-radius: 10px;
  position: relative;
  background-color: ${baseTheme.colors.white};

  @media (max-width: 991px) {
    flex-direction: column;
    padding-top: 50px;
    align-items: center;
    height: 100%;
  }
`;

function Profile() {
  const { error, request } = useAxios();
  const token = getToken();

  const [dataSettingsProfile, setDataSettingsProfile] = useState([]);

  async function getUserInfo() {
    const res = await request("profile/user", "GET", null, {
      Authorization: `Bearer ${token}`,
    });
    saveToLocalStorageUser("user", res?.data || null);
    saveStatusMember(res?.data?.subscribe_status || null);
    // setRole(res?.data?.role || null);
  }

  async function settingsProfile() {
    const res = await request("profile/operation-case/settings", "GET", null, {
      Authorization: `Bearer ${token}`,
    });
    setDataSettingsProfile(res?.data || []);
  }

  useEffect(() => {
    settingsProfile();
    getUserInfo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MainLayout>
      <Wrapper>
        <ProfileSettings
          getUserInfo={getUserInfo}
          settingsProfile={settingsProfile}
          dataSettingsProfile={dataSettingsProfile}
        />
        {error && <Toast error={error} />}
      </Wrapper>
    </MainLayout>
  );
}

export default Profile;
