import HospitalsDropdown from "components/HospitalsDropdown";
import { IHospital } from "interfaces";

import React from "react";

interface IHospitalComponent  {
  setHospital: (value: IHospital) => void;
}

function Hospital({ setHospital }: IHospitalComponent) {
  return <HospitalsDropdown setHospital={setHospital} hideButton={true} />;
}

export default Hospital;
