import Button from "components/Buttons/Button";
import React, { ReactNode, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import { TitleSection } from "styles/components";
import { baseTheme } from "styles/theme";

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const RoundBg = styled.div`
  width: 80px;
  height: 80px;
  margin: 10px 0;
  background-color: ${baseTheme.colors.gray};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
`;

const ModalContainer = styled.div`
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 24px;
  animation: ${fadeIn} 0.3s ease;
  display: flex;
  padding: 20px 10px;
  flex-direction: column;
  align-items: center;
  /* height: 75%; */
  width: 400px;
  overflow: hidden;
  @media (min-width: 1440px) {
    height: auto;
  }
  @media (max-height: 750px) {
    height: 675px;
  }
  @media screen and (max-width: 480px) {
    width: 90%;
  }
  @media (min-width: 1921px) {
    width: 500px;
    height: 750px;
  }
`;

interface ModalProps {
  isOpen: boolean;
  onRequestClose?: () => void;
  children: ReactNode;
  button?: ReactNode;
  icon?: ReactNode;
  title: string;
  text?: string;
}

const Modal: React.FC<ModalProps> = ({
  isOpen,
  icon,
  onRequestClose,
  children,
  title,
  text = "CLOSE",
}) => {
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isOpen]);

  if (!isOpen) {
    return null;
  }

  return (
    <Overlay>
      <ModalContainer>
        <div style={{display: "flex", alignSelf:"flex-end",paddingRight:'10px'}}>
          <Button
            color={baseTheme.colors.black}
            flat={true}
            justify="end"
            fontSize="14px"
            fontWeight={700}
            text={text}
            as="button"
            bg={"transparent"}
            onClick={onRequestClose}
          />
        </div>
        {icon && <RoundBg>{icon}</RoundBg>}

        <TitleSection>{title}</TitleSection>

        {children}
      </ModalContainer>
    </Overlay>
  );
};

export default Modal;
