import React from "react";
import styled from "styled-components";
import { baseTheme } from "styles/theme";


interface TextAreaProps {
  value: string;
  name: string;
  placeholder?: string;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  hasError?: boolean;
  bg?: string;
  color?: string;
}

const StyledInnerWrap = styled.div<{ bg?: string; hasError?: boolean }>`
  background-color: ${({ bg }) => (bg ? bg : baseTheme.colors.gray)};
  display: flex;
  width: 100%;
  padding: 0.75rem;
  font-size: 0.75rem;
  border-radius: 20px;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  transition-duration: 0.3s;
  margin-bottom: 20px;
  border: 1px solid
    ${({ hasError }) =>
      hasError ? baseTheme.colors.error : baseTheme.colors.gray};

  &:focus-within {
    border-color: ${({ hasError }) =>
      hasError ? baseTheme.colors.error : baseTheme.colors.border};
  }
`;

const StyledTextArea = styled.textarea<{ color?: string }>`
  width: 100%;
  outline: none;
  background-color: transparent;
  font-family: "Poppins", sans-serif;
  border-color: transparent;
  line-height: 1.4;
  font-size: 14px;
  border: none;
  resize: none;
  &::placeholder {
    color: ${({ color }) => (color ? color : baseTheme.colors.textGray)};
  }
  @media (min-width: 1921px) {
    font-size: 19px;
  }
`;

const TextArea: React.FC<TextAreaProps> = ({
  value,
  placeholder = "Enter text...",
  onChange,
  hasError = false,
  bg,
  color,
  name,
}) => {
  return (
    <StyledInnerWrap bg={bg} hasError={hasError}>
      <StyledTextArea
        value={value}
        name={name}
        rows={7}
        placeholder={placeholder}
        onChange={onChange}
        color={color}
      />
    </StyledInnerWrap>
  );
};

export default TextArea;
