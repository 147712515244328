import Select from "components/Select";
import setList from "data/set";
import React from "react";

interface ISetLevel {
  setSetLevel: (value: string) => void;
  defaultValue: string;
}

function SetLevel({ setSetLevel, defaultValue }: ISetLevel) {
  return (
    <>
      <Select
        placeholder={defaultValue || "Select Set"}
        options={setList}
        onChange={(value: string | number) => setSetLevel(value.toString())}
      />
    </>
  );
}

export default SetLevel;
