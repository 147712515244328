import { styled } from "styled-components";
import { AccountSettingsHeader } from "./AccountDetails";
import { ProfileSectionTitle } from "./AccountSettings";
import Hospitals from "./Hospitals";
import { baseTheme } from "styles/theme";
import Hospital from "assets/icons/Hospital";
import { useEffect, useReducer, useState } from "react";
import Button from "components/Buttons/Button";
import Supervisors from "./Supervisors";
import OutcomeIcon from "assets/icons/Complications";
import { TitleSection } from "styles/components";
import Rotation from "pages/UserSettingsPage/components/Rotation";
import SetLevel from "pages/UserSettingsPage/components/SetLevel";
import { PgyForm } from "./SetPgy";
import DescriptionModule from "components/Modal/DescriptionModule";
import { getFromLocalStorageUser, getToken, IUserLStorage } from "helpers";
import { useAxios } from "hooks/useAxios";
import InformModal from "components/Modal/ConfirmModal";
import CheckOutlined from "@ant-design/icons/CheckOutlined";

const CoreFieldsContent = styled.div`
  width: 400px;
`;


const Text = styled.p`
  font-size: 16px;
  line-height: 24px;
  text-align: center;
`;

const ContainerText = styled.p``;

const Separator = styled.div`
  padding: 8px 0;
`;

const FirstTextContent = () => {
  return (
    <ContainerText>
      <TitleSection>What are Core Fields?</TitleSection>
      <Text>
        The core fields are the minimum data required for you to enter a
        surgical case into your logbook. They are listed below:
      </Text>
      <Separator />
      <Text>For all users:</Text>
      <Text>Patient UR</Text>
      <Text>Patient DOB</Text>
      <Text>Hospital</Text>
      <Text>Date of procedure</Text>
      <Separator />
      <Text>
        For unaccredited and SET trainees, additional fields required are:
      </Text>
      <Separator />
      <Text>Rotation</Text>
      <Text>PGY/SET Level</Text>
      <Text>Supervisor</Text>
    </ContainerText>
  );
};

const SecondTextContent = () => {
  return (
    <ContainerText>
      <TitleSection>How to use Core Fields</TitleSection>
      <Text>
        Snap Log is designed to be used in an operating theatre immediately
        after you complete a surgical case.
      </Text>
      <Separator />
      <Text>
        In the Core Fields section, you can save your commonly used variables,
        such as:
      </Text>
      <Separator />
      <Text>Hospital</Text>
      <Text>PGY/SET Level</Text>
      <Text>Rotation</Text>
      <Text>Supervisor</Text>
    </ContainerText>
  );
};

interface ICoreFields {
  settingsProfile: () => void;
  dataSettingsProfile: any;
}

function CoreFields({ settingsProfile, dataSettingsProfile }: ICoreFields) {
  const [rotation, setRotation] = useState<string>("");
  const [setLevel, setSetLevel] = useState<string>("");
  const [loadingRotation, setLoadingRotation] = useState(false);
  const [loadingSet, setLoadingSet] = useState(false);
  const [show, toggle] = useReducer((show) => !show, false);
  const [modalText, setModalText] = useState("");

  const token = getToken();
  const { request } = useAxios();
  const user: IUserLStorage = getFromLocalStorageUser("user");
  const { email } = user;
  const [showDescModal, toggleDescModal] = useReducer(
    (showDescModal) => !showDescModal,
    false
  );
  useEffect(() => {
    const checkIfSeen = () => {
      const hasSeen = localStorage.getItem("hasSeenCoreFields");
      if (!hasSeen || JSON.parse(hasSeen).email !== email) {
        toggleDescModal();
      }
    };

    checkIfSeen();
  }, [email]);

  const handleOk = () => {
    localStorage.setItem(
      "hasSeenCoreFields",
      JSON.stringify({ email, seen: true })
    );
    toggleDescModal();
  };

  const [showHospital, toggleHospital] = useReducer(
    (showHospital) => !showHospital,
    false
  );
  const [showSupervisor, toggleSupervisor] = useReducer(
    (showSupervisor) => !showSupervisor,
    false
  );
  async function updateRotation() {
    setLoadingRotation(true);
    const res = await request(
      "profile/presets",
      "POST",
      {
        presets: {
          supervision_level: rotation,
        },
      },
      {
        Authorization: `Bearer ${token}`,
      }
    );
    if (res) {
      setModalText('Rotation changed')
      toggle();
      await settingsProfile();

      setTimeout(() => {
        toggle();
      }, 1500);
    }
    setLoadingRotation(false);
  }

  async function updateSet() {
    setLoadingSet(true);
    const res = await request(
      "profile/presets",
      "POST",
      {
        presets: {
          set: setLevel,
        },
      },
      {
        Authorization: `Bearer ${token}`,
      }
    );
    if (res) {
      toggle();
      setModalText('SET Level changed')
      await settingsProfile();

      setTimeout(() => {
        toggle();
      }, 1500);
    }
    setLoadingSet(false);
  }

  return (
    <>
      <CoreFieldsContent>
        <ProfileSectionTitle>Core Fields</ProfileSectionTitle>
        <AccountSettingsHeader>
          <h2>Hospital</h2>
        </AccountSettingsHeader>
        <Button onClick={toggleHospital} text="Hospital management" />
        {dataSettingsProfile[0]?.operation_case_fields?.rotation && (
          <>
            <AccountSettingsHeader>
              <h2>Rotation</h2>
            </AccountSettingsHeader>
            <Rotation
              defaultValue={dataSettingsProfile[0].presets?.rotation}
              setRotation={setRotation}
            />
            <Button
              loading={loadingRotation}
              as="button"
              onClick={updateRotation}
              text="Save"
            />
          </>
        )}
        {dataSettingsProfile[0]?.operation_case_fields?.supervisor_id && (
          <>
            <AccountSettingsHeader>
              <h2>Supervisor</h2>
            </AccountSettingsHeader>
            <Button
              as="button"
              onClick={toggleSupervisor}
              text="Supervisor management"
            />
          </>
        )}
        {dataSettingsProfile[0]?.operation_case_fields?.set && (
          <>
            <AccountSettingsHeader>
              <h2>SET Level</h2>
            </AccountSettingsHeader>
            <SetLevel
              defaultValue={dataSettingsProfile[0].presets?.set}
              setSetLevel={setSetLevel}
            />
            <Button
              loading={loadingSet}
              as="button"
              onClick={updateSet}
              text="Save"
            />
          </>
        )}
        {dataSettingsProfile[0]?.operation_case_fields?.pgy && (
          <>
            <AccountSettingsHeader>
              <h2>PGY Level</h2>
            </AccountSettingsHeader>
            <PgyForm
              settingsProfile={settingsProfile}
              defaultValue={dataSettingsProfile[0].presets?.pgy}
            />
          </>
        )}
      </CoreFieldsContent>
      <Hospitals
        icon={<Hospital color={baseTheme.colors.main} width={32} height={32} />}
        label={"Add new Hospital"}
        title={"Hospitals"}
        isOpen={showHospital}
        onRequestClose={toggleHospital}
        type={"hospital"}
        loading={false}
        titleList="Hospitals list"
        data={dataSettingsProfile}
        settingsProfile={settingsProfile}
      />
      <Supervisors
        icon={
          <OutcomeIcon color={baseTheme.colors.main} width={32} height={32} />
        }
        label={"Add new Supervisor"}
        title={"Supervisors"}
        isOpen={showSupervisor}
        onRequestClose={toggleSupervisor}
        type={"supervisor"}
        loading={false}
        titleList="Supervisors list"
        data={dataSettingsProfile}
        settingsProfile={settingsProfile}
      />
      <DescriptionModule
        onOk={handleOk}
        showModal={showDescModal}
        secondScreenComponent={<SecondTextContent />}
        firstScreenComponent={<FirstTextContent />}
      />
      <InformModal
        isOpen={show}
        title={modalText}
        icon={
          <CheckOutlined
            style={{ fontSize: "24px", color: baseTheme.colors.main }}
          />
        }
      />
    </>
  );
}

export default CoreFields;
