import Input from "components/Inputs/Input";
import { Section, StyledLabel } from "./styles";
import TextArea from "components/TextArea";
import { LandingContent, useLandingContentStore } from "store/useLandingContentStore";
import { ChangeEvent } from "react";

const Mission = () => {
  const { landingContent, updateField } = useLandingContentStore();
  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    updateField(e.target.name as keyof LandingContent, e.target.value);
  };
  const fields = [
    {
      label: "Mission title",
      name: "missionTitle",
      placeholder: "Enter title",
      Component: Input,
    },
    {
      label: "Mission text",
      name: "missionText",
      placeholder: "Enter mission text",
      Component: TextArea,
    },
  ] as const;

  return (
    <Section>
      
      {fields.map(({ label, name, placeholder, Component }) => (
        <div key={name}>
          <StyledLabel>{label}</StyledLabel>
          <Component
            placeholder={placeholder}
            name={name}
            value={
              (landingContent[name as keyof LandingContent] as string) || ""
            }
            onChange={handleInputChange}
          />
        </div>
      ))}
    </Section>
  );
};

export default Mission;
