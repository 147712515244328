const rotations = [
  { value: "Acute Surgical Unit", label: "Acute Surgical Unit" },
  { value: "Breast & Endocrine", label: "Breast & Endocrine" },
  { value: "Cardiothoracic", label: "Cardiothoracic" },
  { value: "Colorectal", label: "Colorectal" },
  { value: "Emergency", label: "Emergency" },
  { value: "General Surgery", label: "General Surgery" },
  { value: "Head and Neck", label: "Head and Neck" },
  { value: "HPB", label: "HPB" },
  { value: "Intensive Care", label: "Intensive Care" },
  { value: "Neurosurgery", label: "Neurosurgery" },
  { value: "OHNS/ENT", label: "OHNS/ENT" },
  { value: "Oncology", label: "Oncology" },
  { value: "Orthopaedic", label: "Orthopaedic" },
  { value: "Paediatric", label: "Paediatric" },
  { value: "Plastics", label: "Plastics" },
  { value: "Transplant", label: "Transplant" },
  { value: "Trauma", label: "Trauma" },
  { value: "Upper GI", label: "Upper GI" },
  { value: "Urology", label: "Urology" },
  { value: "Vascular", label: "Vascular" },
];

export default rotations;
