import { getFromLocalStorageUser, IUserLStorage } from "helpers";
import Modal from "components/Modal/Modal";
import AndroidTextSub from "./AndroidTextSub";
import IOSTextSub from "./IOSTextSub";
import WebTextSub from "./WebTextSub";

import {
  SubButton,
  Text,
  ListContainer,
  TitleSub,
  SubContainer,
  SubInfo,
  TextWrapper,
} from "./subManagerStyles";
import Button from "components/Buttons/Button";
import { baseTheme } from "styles/theme";
import Subscription from "assets/icons/Subscription";
import CheckOutlined from "@ant-design/icons/CheckOutlined";
interface IModalContainer {
  confirmHandlePayment: () => void;
  subsLoading: boolean;
}

interface ISubscriptionManager {
  isOpen: boolean;
  subsLoading: boolean;
  onRequestClose: () => void;
  confirmHandlePayment: () => void;
}

export function ModalContainer({
  subsLoading,
  confirmHandlePayment,
}: IModalContainer) {
  const user: IUserLStorage = getFromLocalStorageUser("user");
  const { subscription_provider, subscribe_status } = user;
  let titleSubscribe = "";
  switch (subscribe_status) {
    case "demo":
      titleSubscribe = "Would you like to subscribe?";
      break;
    case "demo-expired":
      titleSubscribe =
        "Your free trial has expired. Would you like to subscribe?";
      break;
    case "subscribe-expired":
      titleSubscribe =
        "Your subscription has expired. Would you like to subscribe?";
      break;
  }
  const shouldShowSubscribeButton = [
    "demo",
    "demo-expired",
    "subscribe-expired",
  ].includes(subscribe_status);
  return (
    <SubContainer>
      {(subscription_provider === null || shouldShowSubscribeButton) && (
        <>
          <TextWrapper>
            <Text>{titleSubscribe}</Text>
          </TextWrapper>
          <TitleSub>Our Subscription</TitleSub>

          <ListContainer>
            <ul>
              <li>
                <CheckOutlined
                  style={{
                    paddingRight: "10px",
                    fontSize: "24px",
                    paddingBottom: "5px",
                    color: baseTheme.colors.main,
                  }}
                />
                No hidden fees
              </li>
              <li>
                <CheckOutlined
                  style={{
                    paddingRight: "10px",
                    fontSize: "24px",
                    paddingBottom: "5px",
                    color: baseTheme.colors.main,
                  }}
                />
                Unlimited Case Logs
              </li>
              <li>
                <CheckOutlined
                  style={{
                    paddingRight: "10px",
                    fontSize: "24px",
                    paddingBottom: "5px",
                    color: baseTheme.colors.main,
                  }}
                />
                Customized reports
              </li>
              <li>
                <CheckOutlined
                  style={{
                    paddingRight: "10px",
                    fontSize: "24px",
                    paddingBottom: "5px",
                    color: baseTheme.colors.main,
                  }}
                />
                Free support
              </li>
            </ul>
          </ListContainer>

          <SubButton>
            <Button
              fontWeight={500}
              fontSize="14px"
              loading={subsLoading}
              onClick={confirmHandlePayment}
              bg={baseTheme.colors.main}
              text={"Subscribe"}
            />
            <SubInfo>
              <h1>$9,99/Month</h1>
            </SubInfo>
          </SubButton>
        </>
      )}
      {subscription_provider === "stripe" && !shouldShowSubscribeButton && (
        <WebTextSub subsLoading={subsLoading} confirmHandlePayment={confirmHandlePayment} />
      )}
      {subscription_provider === "apple" && !shouldShowSubscribeButton && (
        <IOSTextSub />
      )}
      {subscription_provider === "google" && !shouldShowSubscribeButton && (
        <AndroidTextSub />
      )}
    </SubContainer>
  );
}

export default function SubscriptionManager({
  isOpen,
  onRequestClose,
  confirmHandlePayment,
  subsLoading,
}: ISubscriptionManager) {
  return (
    <Modal
      icon={<Subscription color={baseTheme.colors.main} />}
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      children={
        <ModalContainer
          subsLoading={subsLoading}
          confirmHandlePayment={confirmHandlePayment}
        />
      }
      title={""}
    ></Modal>
  );
}
