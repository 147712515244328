import Input from "components/Inputs/Input";
import React, { ChangeEvent } from "react";

interface ISupervisor {
  supervisor: string;
  setSupervisor: (value: string) => void;
}

function Supervisor({ supervisor, setSupervisor }: ISupervisor) {
  
  function handleInputChange(e: ChangeEvent<HTMLInputElement>) {
    setSupervisor(e.target.value);
  }

  return (
    <>
      <Input
        type="text"
        name="supervisor"
        required={true}
        placeholder={'Enter Supervisor'}
        value={supervisor}
        onChange={handleInputChange}
      />
    </>
  );
}

export default Supervisor;
