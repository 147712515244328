import { useReducer } from "react";
import { ProfileSectionTitle } from "./AccountSettings";
import { styled } from "styled-components";
import Button from "components/Buttons/Button";
import PathOutcomes from "./PathOutcomes";
import Specimens from "assets/icons/Specimens";
import { baseTheme } from "styles/theme";
import PathIcon from "assets/icons/Pathology";
import ComOutcomes from "./ComOutcomes";
import OutcomeIcon from "assets/icons/Complications";
import { AccountSettingsHeader } from "./AccountDetails";

interface IOutcomes {
  settingsProfile: () => void;
  dataSettingsProfile: any;
}

const WrapperButtons = styled.div`
  width: 400px;
`;
const OutcomeButton = styled.div`
  width: 100%;
  margin: 15px 0;
`;
function Outcomes({ settingsProfile, dataSettingsProfile }: IOutcomes) {
  const [showPathOutcome, togglePathOutcome] = useReducer(
    (showPathOutcome) => !showPathOutcome,
    false
  );
  const [showSpecimens, toggleSpecimens] = useReducer(
    (showSpecimens) => !showSpecimens,
    false
  );
  const [showComOutcome, toggleComOutcome] = useReducer(
    (showComOutcome) => !showComOutcome,
    false
  );

  return (
    <>
      <ProfileSectionTitle>Outcomes</ProfileSectionTitle>
      <WrapperButtons>
        <AccountSettingsHeader>
          <h2>Pathology</h2>
        </AccountSettingsHeader>
        <OutcomeButton>
          <Button onClick={togglePathOutcome} text="Pathology management" />
        </OutcomeButton>
        <AccountSettingsHeader>
          <h2>Complications</h2>
        </AccountSettingsHeader>
        <OutcomeButton>
          <Button onClick={toggleComOutcome} text="Complications management" />
        </OutcomeButton>
        <AccountSettingsHeader>
          <h2>Specimens</h2>
        </AccountSettingsHeader>
        <OutcomeButton>
          <Button onClick={toggleSpecimens} text="Specimens management" />
        </OutcomeButton>
      </WrapperButtons>
      <PathOutcomes
        icon={
          <Specimens color={baseTheme.colors.main} width={32} height={32} />
        }
        label={"Add new specimen"}
        title="Pathology specimens"
        isOpen={showSpecimens}
        onRequestClose={toggleSpecimens}
        type={"pathology-sample"}
        loading={false}
        titleList="Pathology specimens list"
        data={dataSettingsProfile}
        settingsProfile={settingsProfile}
      />
      <PathOutcomes
        icon={<PathIcon color={baseTheme.colors.main} width={32} height={32} />}
        label={"Add new pathology outcome"}
        title={"Pathology outcomes"}
        isOpen={showPathOutcome}
        onRequestClose={togglePathOutcome}
        type={"pathology-outcome"}
        loading={false}
        titleList="Pathology outcomes list"
        data={dataSettingsProfile}
        settingsProfile={settingsProfile}
      />
      <ComOutcomes
        icon={
          <OutcomeIcon color={baseTheme.colors.main} width={32} height={32} />
        }
        label={"Add new complication outcome"}
        title={"Complication outcomes"}
        isOpen={showComOutcome}
        onRequestClose={toggleComOutcome}
        type={"complication-outcome"}
        loading={false}
        titleList="Complication outcomes list"
        data={dataSettingsProfile}
        settingsProfile={settingsProfile}
      />
    </>
  );
}

export default Outcomes;
