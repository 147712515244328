import React, { useState } from "react";
import styled from "styled-components";
import Button from "components/Buttons/Button";

import SetTexField from "./CustomFields/SetTexField";
import SetOptionField from "./CustomFields/SetOptionField";
import OCaseFields from "assets/icons/OperationCaseFields";
import { baseTheme } from "styles/theme";
import Modal from "components/Modal/Modal";
import SetToggleField from "./CustomFields/SetToggleField";
import SetBinaryField from "./CustomFields/SetBinaryField";
import InformModal from "components/Modal/ConfirmModal";
import WarningOutlined from "@ant-design/icons/WarningOutlined";
import { useAxios } from "hooks/useAxios";
import useCustomFieldStore from "store/customFieldId";
import { getToken } from "helpers";

interface AddCustomFieldsProps {
  showModal: boolean;
  onCloseModal: () => void;
  getCustomFields: () => void;
  dataSettingsProfile: any;
}

const NAME_MODAL = "Select Custom Field";
const TEXT = "text";
const BINARY = "binary";
const OPTIONS = "toggle";
const TOGGLE = "options";

const AddCustomFields: React.FC<AddCustomFieldsProps> = ({
  showModal,
  onCloseModal,
  getCustomFields,
  dataSettingsProfile,
}) => {
  const [selectedOption, setSelectedOption] = useState<string | null>(null);
  const [nameModal, setNameModal] = useState(NAME_MODAL);
  const token = getToken();
  const [confirmModule, setConfirmModule] = useState(false);
  const { customFieldId, clearCustomFieldId } = useCustomFieldStore();
  const textCloseButton = nameModal === NAME_MODAL ? true : false;
  const { request } = useAxios();

  const handleOptionClick = (option: string) => {
    setSelectedOption(option);
    setNameModal(option);
  };

  const checkOptions = async () => {
    const customFields = dataSettingsProfile[0].custom_fields || [];
    if (selectedOption === OPTIONS || selectedOption === TOGGLE) {
      if (customFieldId) {
        // const response = await request(
        //   "profile/operation-case/settings",
        //   "GET",
        //   null,
        //   {
        //     Authorization: `Bearer ${token}`,
        //   }
        // );

        const matchedField = customFields.find(
          (field: { id: string; [key: string]: any }) =>
            field.id === customFieldId
        );

        const customFieldValues = matchedField
          ? matchedField["custom-field-values"]
          : [];
        if (customFieldValues.length < 2) {
          setConfirmModule(customFieldValues.length < 2);
        } else {
          clearCustomFieldId();
          closeModal();
        }
      } else {
        closeModal();
      }
    } else {
      closeModal();
    }
  };

  const closeModalOption = () => {
    setSelectedOption(null);
    clearCustomFieldId();
  };

  const confirmAction = async () => {
    setConfirmModule(false);
    const res = await request(
      `profile/custom-field/${customFieldId}/delete`,
      "GET",
      null,
      {
        Authorization: `Bearer ${token}`,
      }
    );
    if (res.message === "Entity successful deleted.") {
      closeModalOption();
      await getCustomFields();
    }
  };
  const closeModal = () => {
    const customFieldsLength = dataSettingsProfile[0].custom_fields.length;

    if (customFieldsLength === 6) {
      setNameModal(NAME_MODAL);
      onCloseModal();
      return;
    }

    if (textCloseButton) {
      onCloseModal();
      // getCustomFields()
    } else {
      closeModalOption();
      setNameModal(NAME_MODAL);
    }
  };

  const renderOptionContent = () => {
    switch (selectedOption) {
      case BINARY:
        return (
          <SetBinaryField
            closeModal={closeModal}
            getCustomFields={getCustomFields}
            title={"Binary options"}
          />
        );
      case TOGGLE:
        return (
          <SetToggleField
            closeModal={closeModal}
            getCustomFields={getCustomFields}
            title={"Toggle custom field"}
          />
        );
      case TEXT:
        return (
          <SetTexField
            closeModal={closeModal}
            getCustomFields={getCustomFields}
            title={"Free text option"}
          />
        );
      case OPTIONS:
        return (
          <SetOptionField
            closeModal={closeModal}
            getCustomFields={getCustomFields}
            title={"Selector with free text options"}
          />
        );
      default:
        return null;
    }
  };

  const options = [
    {
      label: "Binary options",
      onClick: () => handleOptionClick(BINARY),
    },
    { label: "Toggle custom field", onClick: () => handleOptionClick(TOGGLE) },
    {
      label: "Free text option",
      onClick: () => handleOptionClick(TEXT),
    },
    {
      label: "Selector with free text options",
      onClick: () => handleOptionClick(OPTIONS),
    },
  ];

  return (
    <>
      <Modal
        isOpen={showModal}
        text={textCloseButton ? "ClOSE" : "BACK"}
        icon={
          <OCaseFields color={baseTheme.colors.main} width={32} height={32} />
        }
        title={""}
        onRequestClose={checkOptions}
        children={
          !selectedOption ? (
            <ModalContainer>
              {options.map((option, index) => (
                <ButtonContainer key={index}>
                  <Button onClick={option.onClick} text={option.label} />
                </ButtonContainer>
              ))}
            </ModalContainer>
          ) : (
            <ModalContainer>{renderOptionContent()}</ModalContainer>
          )
        }
      />
      <InformModal
        isOpen={confirmModule}
        title={"Please confirm you want to leave."}
        subTitle={
          "This Custom field will not be created because not all options have been configured."
        }
        confirmButton={confirmAction}
        cancelButton={() => setConfirmModule(false)}
        icon={
          <WarningOutlined
            style={{ fontSize: "36px", color: baseTheme.colors.main }}
          />
        }
      />
    </>
  );
};

export default AddCustomFields;

const ModalContainer = styled.div`
  background-color: white;
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

`;

export const ButtonContainer = styled.div`
  cursor: pointer;
  width: 100%;
  margin-top: 16px;
  @media (max-width: 900px) {
    width: 280px;
  }
`;
