import React from "react";
import styled from "styled-components";

import { Column, StyledText, Title } from "./styles";
import { baseTheme } from "styles/theme";

interface CustomField {
  id: string;
  value?: string | boolean;
  "user-custom-field": {
    name: string;
    type: "text" | "boolean" | "toggle" | "options";
  };
  "user-custom-field-value"?: {
    value: string;
  };
}

interface ShowCustomFieldsProps {
  log: {
    operation_case_custom_fields: CustomField[];
  };
}

export const TitleSection = styled.h4`
  color: ${baseTheme.colors.black};
  text-align: center;
  padding: 20px 0;
  font-size: 16px;
  line-height: 1.7;
  font-weight: 600;
  @media (min-width: 1921px) {
    font-size: 18px;
    line-height: 30px;
  }
`;

const CustomDetails: React.FC<ShowCustomFieldsProps> = ({ log }) => {
  const customFields = log?.operation_case_custom_fields;

  const allFieldsNull = customFields?.every(
    (field) => field["user-custom-field"] === null
  );

  return (
    <>
      {!allFieldsNull && <TitleSection>Custom details</TitleSection>}
      {customFields?.map((field) => {
        const userCustomField = field["user-custom-field"];
        const userCustomFieldValue = field["user-custom-field-value"];
        const value = field?.value;
        const id = field?.id;
        let displayValue;

        if (userCustomField?.type === "text") {
          displayValue = value;
        } else if (userCustomField?.type === "boolean") {
          displayValue = value ? "Yes" : "No";
        } else if (
          userCustomField?.type === "toggle" ||
          userCustomField?.type === "options"
        ) {
          displayValue = userCustomFieldValue
            ? userCustomFieldValue.value
            : null;
        }

        return (
          userCustomField && (
            <Column key={id}>
              <Title>{userCustomField.name}</Title>
              <StyledText>{displayValue}</StyledText>
            </Column>
          )
        );
      })}
    </>
  );
};

export default CustomDetails;
