import React, { useReducer } from "react";
import InformModal from "components/Modal/ConfirmModal";
import { useAxios } from "hooks/useAxios";
import Toast from "components/Toast";
import { baseTheme } from "styles/theme";
import { TitleSection } from "styles/components";
import { getToken } from "helpers";
import { Container, FieldContainer, SpinnerWrapper } from "./style";
import EditableField from "./EditableItem";
import DeleteCustomField from "./DeleteCustomField";
import Spinner from "components/Spinners/ButtonSpinner";

interface CustomBinaryFieldProps {
  data: { id: string; name: string };
  getCustomFields: () => void;
  setData?: any;
  closeModal: (isOpen: boolean) => void;
}

const CustomBinaryField: React.FC<CustomBinaryFieldProps> = ({
  data,
  getCustomFields,
  setData,
  closeModal,
}) => {
  const { request, loading, error } = useAxios();

  const [showModal, toggleModal] = useReducer((showModal) => !showModal, false);
  const token = getToken();

  const handleSaveField = async (newValue: string) => {
    const res = await request(
      `profile/custom-field/${data?.id}`,
      "POST",
      {
        name: newValue,
        type: "boolean",
      },
      {
        Authorization: `Bearer ${token}`,
      }
    );
    setData(res.data);
    getCustomFields();
  };

  const onDelete = async () => {
    toggleModal();

    const res = await request(
      `profile/custom-field/${data?.id}/delete`,
      "GET",
      null,
      {
        Authorization: `Bearer ${token}`,
      }
    );
    if (res.message === "Entity successful deleted.") {
      closeModal(false);
      await getCustomFields();
    }
  };

  return (
    <Container>
      <>
        <TitleSection>{`Edit ${data.name} field`}</TitleSection>
        {loading ? (
          <SpinnerWrapper>
            <Spinner color={baseTheme.colors.main} />
          </SpinnerWrapper>
        ) : (
          <>
            <EditableField
              showButton={false}
              id={data?.id}
              value={data.name}
              onSave={handleSaveField}
              onDelete={() => {}}
            />
            <FieldContainer>
              <DeleteCustomField name={data.name} onDelete={toggleModal} />
            </FieldContainer>
          </>
        )}
      </>

      <InformModal
        isOpen={showModal}
        cancelButton={toggleModal}
        confirmButton={onDelete}
        title={"Please confirm deleting item from list"}
      />
      {error && <Toast error={error} />}
    </Container>
  );
};

export default CustomBinaryField;
