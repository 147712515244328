import Button from "components/Buttons/Button";
import SkeletonComponent from "components/Skeletons/Skeleton";
import ToggleSwitch from "components/ToggleSwitcher";

import { getToken } from "helpers";
import { useAxios } from "hooks/useAxios";
import { ProfileSectionTitle } from "pages/Profile/components/AccountSettings";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { baseTheme } from "styles/theme";

const Container = styled.div`
  padding-top: 10px;
  padding-bottom: 10px;
  @media screen and (max-width: 768px) {
    padding-top: 0;
  }
`;
const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  @media screen and (max-width: 768px) {
    padding-top: 5px;
  }
`;
const Form = styled.form`
  width: 350px;
  @media (min-width: 1921px) {
    width: 450px;
  }
`;
const Text = styled.div`
  font-size: 0.95rem;
  @media (min-width: 1921px) {
    font-size: 18px;
  }
`;
interface ICheckOperationFields {
  settingsProfile: () => void;
  dataSettingsProfile: any;
}

function CheckOperationFields({
  dataSettingsProfile,
  settingsProfile,
}: ICheckOperationFields) {
  const token = getToken();
  const { request, loading } = useAxios();

  const [caseFields, setCaseFields] = useState<Record<string, boolean>>({});
  useEffect(() => {
    if (dataSettingsProfile[0].operation_case_fields) {
      setCaseFields(dataSettingsProfile[0].operation_case_fields)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    const res = await request(
      "profile/operation-case/required-fields",
      "POST",
      { operation_case_fields: caseFields },
      {
        Authorization: `Bearer ${token}`,
      }
    );
    if (res) {

      settingsProfile();
    }
  }

  function handleChange(fieldName: string) {
    if (fieldName in caseFields) {
      setCaseFields((prevCaseFields) => {
        return {
          ...prevCaseFields,
          [fieldName]: !prevCaseFields[fieldName],
        };
      });
    }
  }
  function formatLabel(key: string): string {
    return key
      .split("_")
      .filter((word) => word.toLowerCase() !== "id")
      .map((word, index) =>
        index === 0 ? word.charAt(0).toUpperCase() + word.slice(1) : word
      )
      .map((word) => {
        if (word.toLowerCase() === "list") {
          return "";
        } else if (word.toLowerCase() === "hospital") {
          return "Hospital";
        } else {
          return word;
        }
      })
      .filter(Boolean) // Remove empty strings
      .join(" ");
  }

  return (
    <>
    <ProfileSectionTitle>Operation case fields</ProfileSectionTitle>
      <Form onSubmit={handleSubmit}>
        {loading ? (
          <SkeletonComponent
            width={"100%"}
            height={25}
            style={{ margin: "10px 0" }}
            count={6}
          />
        ) : (
          <Container>
            {Object.entries(caseFields).map(([fieldName, fieldValue]) => (
              <Row key={fieldName}>
                <Text>{formatLabel(fieldName)}</Text>
                <ToggleSwitch
                  isChecked={fieldValue}
                  onChange={() => handleChange(fieldName)}
                />
              </Row>
            ))}
          </Container>
        )}
        <Button
          fontWeight={700}
          fontSize="0.8rem"
          text="Save"
          loading={loading}
          color={baseTheme.colors.white}
          bg={baseTheme.colors.main}
        />
      </Form>
    </>
  );
}

export default CheckOperationFields;
