import { baseTheme } from "styles/theme";
import styled from 'styled-components'

export const PaymentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${baseTheme.colors.white};
  height: 100vh;
`;

export const Card = styled.div`
  color: ${baseTheme.colors.white};
  padding: 60px;
  border-radius: 24px;
  box-shadow: 0 2px 3px #c8d0d8;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;

  @media screen and (max-width: 768px) {
    padding: 40px;
  }

  @media screen and (max-width: 480px) {
    padding: 20px;
  }
`;
export const Title = styled.div`
  font-family: "Poppins", sans-serif;
  color: ${props => props.color || baseTheme.colors.main};
  font-weight: 900;
  font-size: 40px;
  margin-bottom: 10px;

  @media screen and (max-width: 768px) {
    font-size: 30px;
  }

  @media screen and (max-width: 480px) {
    font-size: 18px;
  }
`;
export const IconWrapper = styled.div`
  height: 200px;
  width: 200px;
  margin: 10px 0;
  background-color: ${baseTheme.colors.gray};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 768px) {
    height: 150px;
    width: 150px;
  }

`;