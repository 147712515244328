import React from "react";
import styled from "styled-components";
import { ProfileSectionTitle } from "./AccountSettings";

const Container = styled.div`
  border-radius: 10px;
  width: 400px;
  max-width: 90%;
`;

const LinkContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Text = styled.p`
  font-size: 16px;
  line-height: 24px;
  color: #333;
  margin-bottom: 20px;
`;

const Link = styled.a`
  color: #199a8e;
  font-size: 16px;
  font-weight: 500;
  text-decoration: underline;
  cursor: pointer;
`;

const Separator = styled.span`
  margin: 5px 0;
`;
function HelpCenter() {
  return (
    <Container>
      <ProfileSectionTitle>Help Center</ProfileSectionTitle>
      <Text>
        We’re here to help! If you have any questions, feedback, or need
        support, feel free to reach out to us:
      </Text>
      <Text>
        Email:{" "}
        <Link href="mailto:info@snaplog.com.au">info@snaplog.com.au</Link>
      </Text>
      <Text>Our team will get back to you as soon as possible!</Text>
      <LinkContainer>
        <Link target="_blank" href="/terms-and-conditions">
          Terms and Conditions
        </Link>
        <Separator />
        <Link target="_blank" href="/privacy-policy">
          Privacy Policy
        </Link>
      </LinkContainer>
    </Container>
  );
}

export default HelpCenter;
