import { styled } from "styled-components";
import { baseTheme } from "styles/theme";

export const SubContainer = styled.div`
`
export const SubButton = styled.div`
width: '100%';
padding-top: 20px;
`

export const Row = styled.div`
display: flex;
align-items: center;
justify-content: flex-start;
`
export const LinkButton = styled.a`
  color: ${baseTheme.colors.link};
  cursor: pointer;
  text-align: left;
  font-size: 15px;
  line-height: 1.7;
  font-family: "Poppins", sans-serif;
  text-decoration: underline;
`;

export const Text = styled.p`
  font-family: "Poppins", sans-serif;
  color: #222222;
  text-align: left;
  font-size: 16px;
  line-height: 1.7;
  width: 350px;
  word-wrap: break-word;
  @media (min-width: 1921px) {
    font-size: 18px;
  }
`;

export const ListContainer = styled.div`
  padding: 0px 25px;
  ul {
  }
  li {
    align-items: center;
    font-size: 16px;
    letter-spacing: 1px;
    color: #484848;
    font-weight: 400;
    border-bottom: 1px solid #e7e7e7;
    display: flex;
    padding: 10px;
    @media (max-width: 991px) {
      font-size: 16px;
      padding: 7px;
    }
  }
`;

export const SubInfo = styled.div`
  h1 {
    font-size: 12px;
    text-align: center;
    font-weight: 500;
    padding-top: 10px;

  }
`;
export const TitleSub = styled.h4`
  color: ${baseTheme.colors.black};
  text-align: center;
  font-size: 16px;
  line-height: 1.7;
  font-weight: 600;
  @media (min-width: 1921px) {
    font-size: 18px;
    line-height: 28px;
  }
`;

export const TextWrapper = styled.div`
padding: 10px 0 30px;
`