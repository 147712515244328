import Input from "components/Inputs/Input";
import React, { ChangeEvent } from "react";

interface IPGYLevel {
  pgyLevel: string;
  setPgyLevel: (value: string) => void;
}

function PGYLevel({ pgyLevel, setPgyLevel }: IPGYLevel) {
  function handleInputChange(e: ChangeEvent<HTMLInputElement>) {
    setPgyLevel(e.target.value);
  }
  return (
    <>
      <Input
        type="text"
        name="pgy"
        required={true}
        placeholder={"Enter PGY from 1 to 12"}
        value={pgyLevel}
        onChange={handleInputChange}
      />
    </>
  );
}

export default PGYLevel;
