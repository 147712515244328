import { LinkButton, Text } from "./subManagerStyles";
import { getFromLocalStorageUser, IUserLStorage } from "helpers";

export default function AndroidTextSub() {
  const user: IUserLStorage = getFromLocalStorageUser("user");
  const { subscribe_expire_date } = user;
  return (
    <>
      <Text>Your Subscription is active.</Text>
      <Text>Extension date is {subscribe_expire_date}.</Text>
      <Text>Your subscription is managed via Google Play account.</Text>
      <Text>
        To unsubscribe please visit your{" "}
        <LinkButton
          href="https://play.google.com/store/account/subscriptions?hl=en"
          target="_blank"
        >
          Google Play account.
        </LinkButton>
      </Text>

      <Text>
        More information can be found{" "}
        <LinkButton
          href="https://support.google.com/googlepay/answer/7644008?hl=en&co=GENIE.Platform%3DAndroid"
          target="_blank"
        >
          here.
        </LinkButton>
      </Text>
    </>
  );
}
