import { create } from 'zustand';

type CustomFieldState = {
  customFieldId: string;
  setCustomFieldId: (id: string) => void;
  clearCustomFieldId: () => void;
};

const useCustomFieldStore = create<CustomFieldState>((set) => ({
  customFieldId: '',
  setCustomFieldId: (id) => set({ customFieldId: id }),
  clearCustomFieldId: () => set({ customFieldId: '' }),
}));

export default useCustomFieldStore;
