import React from "react";
import RotationOption from "data/rotation";
import Select from "components/Select";

interface IRotation {
  setRotation: (value: string) => void;
  defaultValue: string;
}

function Rotation({ setRotation, defaultValue }: IRotation) {
  return (
    <>
      <Select
        color="#000"
        placeholder={defaultValue || "Rotation"}
        options={RotationOption as any}
        onChange={(value: string | number) => setRotation(value.toString())}
      />
    </>
  );
}

export default Rotation;
