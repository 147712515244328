import { IIcon } from "interfaces";

const EditContentIcon = ({
  color = "#199A8E",
  width = 24,
  height = 24,
}: IIcon) => {
  return (
    <div style={{ width: width, height: height,  }}>
      <svg
        fill={color}
        height={height}
        width={width}
   

        viewBox="0 0 512 512"
     
      >
        <g>
          <g>
            <path
              d="M434.546,151.26h-2.069V10.199C432.476,4.566,427.909,0,422.277,0H89.723c-5.632,0-10.199,4.566-10.199,10.199v242.033
			h-2.069c-33.18,0-60.175,26.994-60.175,60.175c0,33.181,26.995,60.175,60.175,60.175h2.069v129.218
			c0,5.633,4.567,10.199,10.199,10.199h332.554c5.632,0,10.199-4.566,10.199-10.199v-230.19h2.069
			c33.18,0,60.175-26.994,60.175-60.175S467.726,151.26,434.546,151.26z M79.523,352.185h-2.069
			c-21.933,0-39.777-17.844-39.777-39.777s17.844-39.777,39.777-39.777h2.069V352.185z M412.078,151.26H382.83v-18.488
			c0-8.314-4.299-15.76-11.499-19.916c-7.201-4.157-15.798-4.158-23,0.001l-49.678,28.686c-7.201,4.157-11.498,11.603-11.498,19.916
			c0,8.313,4.298,15.759,11.497,19.915l49.679,28.687h0.001c3.6,2.08,7.549,3.118,11.5,3.118c3.949,0,7.898-1.039,11.499-3.118
			c7.201-4.157,11.499-11.603,11.499-19.917v-18.488h29.248v319.945H99.922V372.583h124.101v18.488
			c0,8.314,4.299,15.76,11.499,19.917c3.6,2.079,7.549,3.118,11.499,3.118c3.95,0,7.9-1.039,11.501-3.119l49.678-28.686
			c7.201-4.157,11.498-11.603,11.498-19.916s-4.298-15.759-11.497-19.916l-49.679-28.687h-0.001c-7.202-4.158-15.8-4.158-22.999,0
			c-7.201,4.157-11.499,11.602-11.499,19.916v18.488h-124.1V20.398h312.156V151.26z M362.431,132.772v57.375
			c0,1.24-0.707,1.909-1.299,2.251s-1.527,0.62-2.6,0l-49.68-28.687c-1.074-0.62-1.298-1.567-1.298-2.251
			c0-0.684,0.225-1.631,1.299-2.252l49.678-28.686c1.075-0.62,2.007-0.343,2.601,0C361.723,130.864,362.431,131.532,362.431,132.772
			z M244.421,391.07v-57.375c0-1.24,0.707-1.908,1.299-2.251c0.337-0.195,0.783-0.368,1.302-0.368
			c0.396,0.001,0.833,0.101,1.297,0.369l49.68,28.687c1.074,0.62,1.298,1.567,1.298,2.251c0,0.684-0.225,1.631-1.299,2.251
			l-49.678,28.686c-1.075,0.622-2.009,0.343-2.601,0C245.128,392.979,244.421,392.31,244.421,391.07z M434.546,251.213h-2.069
			v-79.554h2.069c21.933,0,39.777,17.844,39.777,39.777S456.478,251.213,434.546,251.213z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              d="M364.111,62.215h-7.139c-5.632,0-10.199,4.566-10.199,10.199c0,5.633,4.567,10.199,10.199,10.199h7.139
			c5.632,0,10.199-4.566,10.199-10.199C374.31,66.781,369.743,62.215,364.111,62.215z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              d="M317.195,62.215H147.888c-5.632,0-10.199,4.566-10.199,10.199c0,5.633,4.567,10.199,10.199,10.199h169.307
			c5.632,0,10.199-4.566,10.199-10.199C327.394,66.781,322.827,62.215,317.195,62.215z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              d="M285.577,239.681H147.888c-5.632,0-10.199,4.566-10.199,10.199s4.567,10.199,10.199,10.199h137.689
			c5.632,0,10.199-4.566,10.199-10.199S291.209,239.681,285.577,239.681z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              d="M239.681,123.41h-91.793c-5.632,0-10.199,4.566-10.199,10.199c0,5.633,4.567,10.199,10.199,10.199h91.793
			c5.632,0,10.199-4.566,10.199-10.199S245.313,123.41,239.681,123.41z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              d="M239.681,178.486h-91.793c-5.632,0-10.199,4.566-10.199,10.199c0,5.633,4.567,10.199,10.199,10.199h91.793
			c5.632,0,10.199-4.566,10.199-10.199C249.88,183.052,245.313,178.486,239.681,178.486z"
            />
          </g>
        </g>
      </svg>
    </div>
  );
};

export default EditContentIcon;
